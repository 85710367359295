import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as ColorSchemes from 'chartjs-plugin-colorschemes';

@Component({
  selector: 'app-bar-basic',
  templateUrl: './bar-basic.component.html',
  styleUrls: ['./bar-basic.component.css']
})
export class BarBasicComponent implements OnInit {
  @Input()
  // tslint:disable-next-line:variable-name
  public set inputData(_data: any){
    this.data = _data;
    this.updateData();
  }
  data = undefined;
  totalPieValue = 0;
  // plugins = [ChartDataLabels];
  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend:{
      position: "left",
      reverse: true,
      // labels: {
      //   fontColor: 'white'
      // }
    },
    plugins: {
      // Change options for ALL labels of THIS CHART
      datalabels: {
        // color: 'white',
        display: true,
        clamp: true,
        formatter(value, context) {
          if (context.dataset.data.length === 0) {
            return 0;
          }
          // @ts-ignore
          const singlePercent = (context.dataset.data.reduce((a, b) => a + b, 0)) / 100;
          return Math.round(value / singlePercent) + '%';
        }
      },
      colorschemes: {
        scheme: 'brewer.RdYlGn10',
        override: true
        // reverse: true,
      }
    },
    // scale s: {
    //   yAxes: [{
    //     id: 'A',
    //     type: 'linear',
    //     position: 'left',
    //     ticks: {
    //       beginAtZero: true
    //     },
    //     stacked: true
    //   }],
    //   xAxes:[{
    //     stacked: true
    //   }]
    // }
  };
  chartLabels: Label[] = [];
  chartType: ChartType = 'doughnut';
  chartLegend = true;
  chartPlugins = [ChartDataLabels, ColorSchemes];

  chartData: ChartDataSets[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  updateData(){
    if (this.data === undefined) { return; }
    console.log('this.data', this.data);
    this.totalPieValue = this.data.data.reduce((a, b) => a + b, 0);
    this.chartLabels = this.data.labels;
    this.chartData = [{data: this.data.data}];
    // for(const key of Object.keys(this.data.totals)){
    //   this.chartData.push({data: this.data.totals[key], label: key});
    // }
  }


}
