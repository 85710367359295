import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgStrapiAuthService} from "../services/strapi/auth/ng-strapi-auth.service";

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  // providers: [MyserviceService]
})
export class LoginComponent implements OnInit {
  msg = '';
  emailConfirmed = false;
  loginHappening = false;
  registeredDifferently: boolean = false;
  constructor( private authService: NgStrapiAuthService ,private routes: Router, private route: ActivatedRoute,
               @Inject(DOCUMENT) private document: Document) {


  }

  async check(uname: string, p: string) {
      this.loginHappening = true;
    try{
        const user = await this.authService.signIn(uname, p);
        console.log('user', user);
        // if (user-profile == true) {
            this.routes.navigate(['dashboard']);
        // } else {
        //     this.msg = 'Invalid Username or Password';
        // }
    } catch (e){
        console.log('e', e[0].messages[0].message)
         const errorMsg = e[0].messages[0].message;
        this.loginHappening = false;
        // if(errorMsg === 'Identifier or password invalid.'){
        //
        //     return this.msg = 'Неверный логин или пароль'
        // }
        // if(errorMsg === 'Your account email is not confirmed'){
        //     return this.msg = 'Пожалуйста подтвердите ваш аккаунт по ссылку из email'
        // }
        this.msg = errorMsg;
    }

      this.loginHappening = false;
    }

  async ngOnInit() {
      try{
          let status = this.authService.autoSignIn();
          console.log('status', status)
          this.routes.navigate(['dashboard']);
      } catch (e){
          console.log('e', e)
      }
      this.emailConfirmed = Boolean(this.route.snapshot.queryParamMap.get('emailConfirm'));
      this.registeredDifferently = Boolean(this.route.snapshot.queryParamMap.get('registeredDifferently'));
      // console.log('other param', );
  }

    openExternal(link) {
        this.document.location.href = link;
    }
}
