import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import {NgStrapiAuthService} from "../../../services/strapi/auth/ng-strapi-auth.service";

@Component({
  selector: 'app-google',
  templateUrl: './google.component.html',
})
export class GoogleComponent implements OnInit {

  constructor(private authService: NgStrapiAuthService, private router: Router, private httpClient: HttpClient) { }


  async ngOnInit() {
  //  id_token
    let params  = this.router.url;
    console.log('params', params)
    const isGoogle = params.indexOf('google') > 1;
    const isFacebook = params.indexOf('facebook') > 1;
    console.log('isGoogle', isGoogle)
    const copy = params.slice(params.indexOf('?'));
    try{
      let url = environment.apiUrl;
      if(isGoogle) url += '/auth/google/callback';
      if(isFacebook) url += '/auth/facebook/callback';
      const res: any = await this.httpClient
          .get(url + copy)
          .toPromise();
      console.log('res', res)
      this.authService.user = res.user;
      this.authService.jwt = res.jwt;
      this.authService.saveCredentials();
      this.authService.autoSignIn();
      this.router.navigate(['dashboard']);
    } catch(e){
      console.log('e', e)
      this.router.navigate(['login?registeredDifferently=true']);
    }
  }

}
