import { Component, OnInit } from '@angular/core';

import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
    public basicInfoLoaded = false;

    public sessions: any = [];
    public confirmedSessions: any = [];
    public loaded: any = false;
    public errors: any;
    
    public userStatus = {
        'dataStatus': 'loading', //loading, loaded
        'userStatus': undefined, //new, test_session_booked, test_session_completed, subscriber
        'formStatus': 'incomplete', //incomplete, completed
        'nextSession': undefined, //date
        'currentSubscription': undefined, //no_subscription, active_subscription, expired_subscription,
        'trainingSessionsCount': 0
    };

    private sessionTypes: any;
    private bookedSessionQuery: Subscription;


  constructor(private router: Router) { }

  ngOnInit(): void {
      // let options = { weekday: 'long', hour: '2-digit', minute: '2-digit', year: 'numeric', month: 'long', day: 'numeric' };
      // let options = { weekday: 'long' };
      //
      // console.log((new Date()).toLocaleDateString(undefined, options));
      // this.platform.getUserState().subscribe(data => {
      //     if(data === undefined) return
      //     this.userStatus = data;
      //     this.loaded = true;
      // });


    // this.platform.getSessionTypes().subscribe(data => {
    //     if(data === undefined) return
    //     this.sessionTypes = data.sessionTypes;
    // });

  }

  ngOnDestroy(){
      // this.bookedSessionQuery.unsubscribe();
  }


  navigate(link){
      this.router.navigate([link])
      // let sessionToBook = this.sessionTypes.find(session => session.Name === type);
      // this.bookSessionRoute(sessionToBook.id)
  }

    navigateExternal(link) {
        window.open(link, "_blank");
    }
}
