import {Component, Input, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';
import {RestaurantsService} from "../../../eyezilla_charts/shared/restaurants.service";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {FormControl} from "@angular/forms";
import moment from "moment";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})


export class AppBreadcrumbComponent implements OnInit {
  @Input() layout;
  pageInfo;
  restaurantLocation;
  selectedRestaurant: any;
  // date: any;
  date = new FormControl();

  constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private titleService: Title,
      public restaurantsService: RestaurantsService,
  ) {


    this.restaurantsService.selectedRestaurantData.subscribe(restaurant => {
      this.selectedRestaurant = Object.assign({}, restaurant);
      console.log('restaurant update', restaurant)
    })

    this.restaurantsService.selectedDateSourceData.subscribe(dateMoment => {
      this.date.setValue(dateMoment.day(2).toISOString().substring(0, 10))
      console.log('dateMoment update', dateMoment)
    })

    this.router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .pipe(map(() => this.activatedRoute))
        .pipe(
            map(route => {
              while (route.firstChild) {
                route = route.firstChild;
              }
              return route;
            })
        )
        .pipe(filter(route => route.outlet === 'primary'))
        .pipe(mergeMap(route => route.data))
        .subscribe(event => {
          this.titleService.setTitle(event['title']);
          this.pageInfo = event;
        });
  }

  ngOnInit() {
  }


  dateInput(change: string, $event: MatDatepickerInputEvent<unknown>) {
    // console.log('date', this.date)
    // console.log('moment().day(0).toISOString()', moment(this.date.value).day(2).toISOString())
    // console.log('moment().day(6).toISOString()', moment(this.date.value).day(8).toISOString())
    const date = moment(this.date.value).day(2)
    this.date.setValue(date.toISOString().substring(0, 10))

    this.restaurantsService.setDate(date)
  }

  restaurantSelect(event) {
    console.log('event', event)
    console.log('this.selectedRestaurant.id', this.selectedRestaurant.id)
    this.restaurantsService.changeRestaurant(event)

  }
}
