import { Component, OnInit, Input } from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import {DictService} from '../../services/dict.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-target-qos-chart',
  templateUrl: './target-qos-chart.component.html',
  styleUrls: ['./target-qos-chart.component.css']
})
export class TargetQosChartComponent implements OnInit {
  @Input()
  // tslint:disable-next-line:variable-name
  public set inputData(_data: any) {
    this.data = _data;
    this.updateData();
  }



  data = undefined;

  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      // Change options for ALL labels of THIS CHART
      datalabels: {
        // color: 'white',
        display: true,
        clamp: true,
        formatter: function(value, context) {
          return (Math.round(value * 100) / 100);
        }
      },
      // colorschemes: {
      //   scheme: 'tableau.Classic20',
      //   override: true
      //   // reverse: true,
      // }
      },
    // legend: {
    //   labels: {
    //     fontColor: 'white'
    //   }
    // },
    scales: {
      yAxes: [{
        stacked: true,
        // ticks: {
        //   fontColor: 'white'
        // },
        // id: 'A',
        // type: 'linear',
        // position: 'left',
        // ticks: {
        //   beginAtZero: true
        // }
      }],
      xAxes: [{
        stacked: true,
        // ticks: {
        //   fontColor: 'white'
        // },
      }]
    }
  };
  chartLabels: Label[] = [];
  chartType: ChartType = 'horizontalBar';
  chartLegend = true;
  chartPlugins = [ChartDataLabels];

  chartData: ChartDataSets[] = [];

  constructor(private dict: DictService) {
  }

  ngOnInit(): void {
  }



  updateData() {
    if (this.data.targetQoS === undefined || this.data.averageQoS === undefined) {
      return;
    }
    console.log('this.data', this.data);
    this.chartLabels = ['Target', 'Average'];
    const dict = this.dict.inputToSim;
      // Object.keys(this.data);
    // , label: key
    this.chartData = []
    for (const key of Object.keys(this.data.targetQoS)) {
      if (['Total', 'Mains to checkback'].indexOf(key) > -1) { continue; }
      this.chartData.push({data: [this.data.targetQoS[key], this.data.averageQoS[dict[key]]], label: key});
      // this.chartData.push({data: [this.data[key]], label: key});
    }

  }
}
