import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DictService {

  public simToInput = {
    arrivalToOrder: 'Arrival to order',
    billDropToPayment: 'Bill drop to payment',
    billReqToBillDrop: 'Bill request to bill drop',
    endOfEatingToPlates: 'End of eating to plates collection',
    mainToEndOfEating: 'Mains to end fo eating',
    orderToMain: 'Order to Mains',
    paymentToLeave: 'Payment to leave',
    platesToUpsell: 'Plates collection to upsell attempt',
    upsellToBillReq: 'Upsell attempt to Bill request',
  };

  public inputToSim = {};

  public waiterNames = {
    1: 'Eva Brookes',
    2: 'Joanne Crosby',
    3: 'Paul Hazlewood',
    4: 'David Hillman',
    5: 'Terence McCarthy',
    6: 'John Muldoon',
    7: 'Helen Oldham',
    8: 'Timothy Paling',
    9: 'Bill Gates'
  }

  public cardInfo = {
    totalGuests: "Live video stream from CCTV camera",
    averageWaiting: "Time and number of tranactions waiting or queuing at station",
    Occupancy: "Restaurant occupancy in percentages",
    understaffedOverstaffed: "Positive number reflects how many waiters the restaurant actually needs at each timeslot to provide the best quality of service. Positive number means more waiters needed. Negative number means there were too many waiters.",
    waiterNumber: "Number of waiters on shift at each timeslot",
    waiterTablesSlots: "Average number of tables each waiter is serving at each timeslot",
    qosConcurrentTables1: "Delivered quality of service VS number of tables waiter is serving simultaneously",
    qosOnTimeslots: "Average QoS at each timeslot",
    guestsQosSplitBar: "Average distribution of time spent queueing and waiting for each station for average transaction",
    targetQos: "Desired quality of service VS actually quality of service",
  }

  constructor() {
    this.inputToSim = this.objectFlip(this.simToInput);
  }

  objectFlip(obj) {
    const ret = {};
    Object.keys(obj).forEach(key => {
      ret[obj[key]] = key;
    });
    return ret;
  }

}
