import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import {SimulationService} from '../services/simulation.service';
import {DataProcessingService} from '../services/data-processing.service';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {DictService} from '../services/dict.service';
import {MatSort} from '@angular/material/sort';
import {NgModel} from '@angular/forms';
import {MatSidenav} from '@angular/material/sidenav';
import {MatPaginator} from '@angular/material/paginator';
import {MatTabGroup} from '@angular/material/tabs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {RestaurantsService} from '../shared/restaurants.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('endSidenav') sidenav: MatSidenav;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('overviewTabs') overviewTabs: MatTabGroup;
  @ViewChild('tableOccupancyChart', {read: ElementRef, static: false}) elementView: ElementRef;

  public restaurantInformation: any;
  public targetQoS: any;
  public simWeekdays: any[];
  // Graphs inputs
  public totalTablesInputData: any = [];
  public totalGuestsInputData: any = [];
  public waitingAtOccupancyInputData: any = undefined;
  public arrivalToOrderAtOccupancyInputData: any = undefined;
  public totalQosDev: any = undefined;
  public averageQoS: {};
  public weekSummary: any = {
    totalGuests: 0,
    totalTableCovers: 0,
    bestDay: 'Sunday',
    bestDayGuests: 0,
    bestDayTableCovers: 0,
    worstDay: 'Monday',
    worstDayGuests: 0,
    worstDayTableCovers: 0,
  };
  public averageWaitingAtTimeSlots: any = undefined;
  // UI variables
  public restaurantStats: any = {};
  totalQosColumnSplit: any = 'weekdays';
  totalQosFilters: any = {
    qos: ['arrivalToOrder',
      'billDropToPayment',
      'billReqToBillDrop',
      'endOfEatingToPlates',
      // 'mainToEndOfEating',
      'orderToMain',
      'paymentToLeave',
      'platesToUpsell',
      'upsellToBillReq', ],
    occupancy: ['0%-10%', '10%-20%', '20%-30%', '30%-40%', '40%-50%', '50%-60%', '60%-70%', '70%-80%', '80%-90%', '90%-100%', ],
    slots: ['10-11', '11-12', '12-13', '13-14', '14-15', '15-16', '16-17', '17-18', '18-19', '19-20', '20-21', '21-22', '22-23', '23-24'],
    weekdays: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ],
    waiters: []
  };
  totalQosFiltersWeekdays: any = Object.assign([], this.totalQosFilters.weekdays);
  totalQosFiltersSlots = Object.assign([], this.totalQosFilters.slots);
  totalQosFiltersOccupancy = Object.assign([], this.totalQosFilters.occupancy);
  totalQosFiltersWaiters = Object.assign([], this.totalQosFilters.waiters);
  qosDeviationFilterDisplayedColumns: string[] = ['select', 'name', 'stDev'];
  qosDeviationFilterDataSource = new MatTableDataSource();
  qosDeviationFilterSelection = new SelectionModel(true, [], true);
  waitingWaiterTableInputData: any;
  averageWaiterTableAtTimeSlots: any;
  tableOccupancy: any = undefined;
  public occupancyAtTimeSlots: any = undefined;
  savingPotential: {};
  waitersDisplayedColumns: string[] = ['id', 'name', 'averageWaiting', 'averageConcurentTables', 'averageDeviation', 'action'];
  waiterTableDataSource = new MatTableDataSource();
  // public waitersStatistics: any = [];
  public averageQosAtTimeSlots: any = undefined;
  public waitersAtTimeSlots: any = undefined;
  public qosConcurrentTables: any = [];
  public staffingGraph: any = undefined;
  public qosPie: any = undefined;
  public tableOccupancyWeekday: any = 'Monday';
  public waiterShifts: { eveningEnd: string; morningStart: string; morningEnd: string; eveningStart: string };
  public qosScore = 8.1;
  public staffingScore = 6;
  public savingPotentialScore = 1221;
  public averageCoverBill = 40;
  filterStatus: any = false;
  currentTab: string[];
  showSpinner: boolean;
  private simulationData: any[];
  private totalQosFilterCopy: any;
  private restaurantDate: any;

  constructor(public router: Router, public restaurant: RestaurantsService, private ref: ChangeDetectorRef, private simulation: SimulationService, private dps: DataProcessingService, public dict: DictService, public dialog: MatDialog) {
    // this.restaurant = this.route.snapshot.paramMap.get('restaurant'); //get id parameter
    // console.log('this.restaurant', this.restaurant)

  }

  objectKeys(object) {
    if (object === undefined) {
      return [];
    }
    return Object.keys(object);
  }

  ngOnInit() {
    this.showSpinner = true;
    this.totalQosFilters.waiters = Object.values(this.dict.waiterNames);
    this.totalQosFilterCopy = Object.assign({}, this.totalQosFilters);
    this.totalQosFiltersWaiters = Object.assign([], this.totalQosFilters.waiters);
    this.restaurant.selectedRestaurantData.subscribe(restaurant => {
      console.log('this.restaurantInformation', this.restaurantInformation);
      if (this.restaurantInformation === undefined) {
        this.restaurantInformation = restaurant;
      } else {
        this.restaurantInformation = restaurant;
        this.showSpinner = true;
        // this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        //   this.router.navigate(['overview']);
        // });
        this.dps.cleanUpData();
        setTimeout(() => {
          console.log('Launching from selectedRestaurantData');
          this.starter();
        });
      }
      // console.log('overview restaurant', restaurant)
      // this.restaurantInformation = restaurant;
    });

    this.restaurant.selectedDateSourceData.subscribe(async (date) => {
      console.log('overview date', date.format());
      this.restaurantDate = date;
      if (this.restaurantDate === undefined) {
        this.restaurantDate = date;
      } else {
        this.restaurantDate = date;
        this.showSpinner = true;

        // console.log('here', 'overview')
        // await this.router.navigateByUrl('/', { skipLocationChange: true });
        // this.router.navigateByUrl('/overview');
        // this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        //   this.router.navigate(['overview']);
        // });
        this.dps.cleanUpData();
        setTimeout(() => {
          console.log('Launching from selectedDateSourceData');
          this.starter();
        });

      }
    });
  }

  async starter(): Promise<void> {
    // this.simulation.removeSimulationData();

    console.log('Launchung ngAfterViewInit');
    this.simulationData = await this.simulation.loadData(this.restaurantInformation.path, this.restaurantDate.format('DD.MM.YYYY'));
    this.dps.setSimulationData(this.simulationData);


    this.targetQoS = await this.simulation.getTargetQoS();
    this.restaurantStats = this.dps.getRestaurantStats();

    this.drawBasicTotals();
    // this.drawOccupancyWaitingTime();
    // this.drawTargetAverageQoS();
    this.getWeekSummary();

    // this.drawOccupancyConcurentTablesTime();

    // this.drawTotalQosDev();


    // this.arrivalToOrderAtOccupancyInputData = this.dps.waitingAtOccupancy(20);
    this.waiterTableDataSource.sort = this.sort;

    await this.reloadDynamicGraphs(true);
    this.showSpinner = false;
    //  WORK IN PROGRESS
    this.testerFunction();
  }

  // getSpinnerStatus(){
  //   console.log('this.showSpinner', this.showSpinner)
  //   return this.showSpinner;
  // }
  async reloadDynamicGraphs(boot = false) {

    // console.time('start')
    this.showSpinner = true;
    this.ref.detectChanges();
    console.log('this.showSpinner ', this.showSpinner);
    await this.dps.setDataFrame(this.restaurantInformation,  this.restaurantDate.format('DD.MM.YYYY'), this.totalQosFilters, this.targetQoS);
    // console.timeEnd('start')


    this.drawTotalQosDev();
    this.drawTableOccupancy();


    await this.displaySavingPotential();
    await this.drawQosOnTimeslots();


    await this.drawTargetAverageQoS();
    if (boot) {
      this.reloadQoSDeviation();
      this.qosDeviationFilterSsAllSelected() ?
          this.qosDeviationFilterSelection.clear() :
          this.qosDeviationFilterDataSource.data.forEach(row => this.qosDeviationFilterSelection.select(row));

      await this.drawStaffingGraph();
      this.drawQosConcurrentTables();
      await this.drawAverageConcurentTablesAtTimeSlots();
      await this.drawWaitersOnTimeslots();
      await this.drawOccupancyAtTimeSlots();
      await this.drawAverageWaitingTimeAtTimeSlots();
      await this.loadWaitersData();
    } else {
      this.qosDeviationFilterSsAllSelected() ?
          this.qosDeviationFilterSelection.clear() :
          this.qosDeviationFilterDataSource.data.forEach(row => this.qosDeviationFilterSelection.select(row));

      await this.drawStaffingGraph();
      this.drawQosConcurrentTables();
      await this.drawAverageConcurentTablesAtTimeSlots();
      await this.drawWaitersOnTimeslots();
      await this.drawOccupancyAtTimeSlots();
      await this.drawAverageWaitingTimeAtTimeSlots();
      await this.loadWaitersData();
      this.reloadQoSDeviation();
    }

    this.drawQosPie();
    this.showSpinner = false;
    this.ref.detectChanges();
    console.log('this.showSpinner ', this.showSpinner);
  }

  drawBasicTotals() {
    this.totalTablesInputData = this.dps.getTotalTables();
    this.totalGuestsInputData = this.dps.getTotalGuests();
  }

  drawOccupancyWaitingTime() {
    this.waitingAtOccupancyInputData = this.dps.waitingAtOccupancy(20, 'length');
  }

  drawOccupancyConcurentTablesTime() {
    this.waitingWaiterTableInputData = this.dps.waitingAtOccupancy(20, 'concurentTables');
  }

  selectAll(select: NgModel, values) {
    select.update.emit(values);
  }

  deselectAll(select: NgModel) {
    select.update.emit([]);
  }

  async drawTargetAverageQoS() {

    this.averageQoS = await this.dps.getAverageQoS();
    console.log('this.averageQoS', this.averageQoS);
  }

  getWeekSummary() {
    this.weekSummary = this.dps.getWeekSummary();
    console.log('this.weekSummary', this.weekSummary);
  }

  async drawAverageWaitingTimeAtTimeSlots() {
    const timeSlots = await this.simulation.getTimeSlots();
    this.averageWaitingAtTimeSlots = await this.dps.getDataAtTimeSlots(timeSlots, 'length', this.totalQosFilters);
  }

  async drawAverageConcurentTablesAtTimeSlots() {
    const timeSlots = await this.simulation.getTimeSlots();
    this.averageWaiterTableAtTimeSlots = await this.dps.getDataAtTimeSlots(timeSlots, 'concurentTables', this.totalQosFilters);
  }


  async drawTotalQosDev(params: any = {}) {
    const targetQos = params.targetQos === undefined ? this.targetQoS : params.targetQos;
    const filters = params.filters === undefined ? this.totalQosFilters : params.filters;
    const columnSplit = params.columnSplit === undefined ? [this.totalQosColumnSplit] : params.columnSplit;
    console.log('filters', filters);
    this.totalQosDev = await this.dps.getTargetQosDeviation(targetQos, filters, columnSplit);

  }

  drawTableOccupancy() {
    this.tableOccupancy = this.dps.getTablesOccupancy(this.targetQoS, this.totalQosFilters);
  }

  async drawOccupancyAtTimeSlots() {
    const timeSlots = await this.simulation.getTimeSlots();
    this.occupancyAtTimeSlots = await this.dps.getDataAtTimeSlots(timeSlots, 'occupancyCount', this.totalQosFilters);
  }

  async displaySavingPotential() {
    const timeSlots = await this.simulation.getTimeSlots();
    this.savingPotential = await this.dps.calculateSavingPotential(timeSlots, this.tableOccupancy, this.targetQoS, this.totalQosFilters);
    console.log('this.savingPotential', this.savingPotential);
    let totalPotential = 0;
    Object.keys(this.savingPotential).forEach(weekday => {
      Object.keys(this.savingPotential[weekday]).forEach(timeslot => {
        totalPotential += this.savingPotential[weekday][timeslot];
      });
    });
    this.savingPotentialScore = totalPotential * this.averageCoverBill;
  }

  async testerFunction() {


  }

  async loadWaitersData() {
    this.waiterTableDataSource.data = await this.dps.getWaitersStats(this.totalQosFilters, this.targetQoS);
    console.log('this.waiterTableDataSource.data', this.waiterTableDataSource.data);
  }

  reloadQoSDeviation() {
    const result = [];
    const qosStDev = this.dps.getQosStDev(this.totalQosFilters, this.targetQoS);
    console.log('qosStDev', qosStDev);
    // this.averageQoS = qosStDev;
    Object.keys(qosStDev).forEach(key => {
      result.push({name: this.dict.simToInput[key], stDev: qosStDev[key]});
    });
    // result.sort((a, b) => b.stDev - a.stDev);

    if (this.qosDeviationFilterDataSource.data.length === 0) {
      this.qosDeviationFilterDataSource.data = result;
    } else {
      this.qosDeviationFilterDataSource.data.forEach(point => {
        // @ts-ignore
        point.stDev = qosStDev[this.dict.inputToSim[point.name]];
      });
    }

  }

  loadQosDeviationFilterQos() {
    this.reloadQoSDeviation();
    this.qosDeviationFilterMasterToggle();

  }

  async totalQosColumnSplitChange(event) {
    console.log('event', event.value);
    this.totalQosDev = await this.dps.getTargetQosDeviation(this.targetQoS, this.totalQosFilters, [event.value]);

  }

  /** Whether the number of selected elements matches the total number of rows. */
  qosDeviationFilterSsAllSelected() {
    const numSelected = this.qosDeviationFilterSelection.selected.length;
    const numRows = this.qosDeviationFilterDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  qosDeviationFilterMasterToggle() {
    this.qosDeviationFilterSsAllSelected() ?
        this.qosDeviationFilterSelection.clear() :
        this.qosDeviationFilterDataSource.data.forEach(row => this.qosDeviationFilterSelection.select(row));
    this.qosFilterChange();
  }

  /** The label for the checkbox on the passed row */
  qosDeviationFilterCheckboxLabel(row = undefined): string {
    if (!row) {
      return `${this.qosDeviationFilterSsAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.qosDeviationFilterSelection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  async pageFilterChange() {
    this.showSpinner = true;
    setTimeout(() => {
      this.reloadDynamicGraphs();
    });

  }

  async qosFilterChange() {
    const qosFilter = this.qosDeviationFilterSelection.selected.map(param => {
      return this.dict.inputToSim[param.name];
    });

    this.totalQosFilters.qos = qosFilter;

    // this.reloadDynamicGraphs();
    // this.reloadQoSDeviation();
    await this.dps.setDataFrame(this.restaurantInformation,  this.restaurantDate.format('DD.MM.YYYY'), this.totalQosFilters, this.targetQoS);
    this.drawTotalQosDev();
    console.log('qosFilter', qosFilter);
  }

  qosFilterChangeClick(row: any) {
    this.qosDeviationFilterSelection.toggle(row);
    this.qosFilterChange();
  }

  async drawQosOnTimeslots() {
    const timeSlots = await this.simulation.getTimeSlots();
    this.averageQosAtTimeSlots = await this.dps.getDataAtTimeSlots(timeSlots, 'deliveredQos', this.totalQosFilters);
  }

  async drawWaitersOnTimeslots() {
    const timeSlots = await this.simulation.getTimeSlots();
    const waiterShift = this.simulation.getShifts();
    const waiterData = await this.dps.getDataAtTimeSlots(timeSlots, 'waitersNum', this.totalQosFilters);
    const indexOfMorningEnd = waiterData.labels.indexOf(waiterShift.morningEnd);
    Object.keys(waiterData.extendedData).forEach(weekday => {
      const morningMax = Math.max(...waiterData.extendedData[weekday].slice(0, indexOfMorningEnd));
      const eveningMax = Math.max(...waiterData.extendedData[weekday].slice(indexOfMorningEnd));
      const newMorning = new Array(indexOfMorningEnd).fill(morningMax);
      const newEvening = new Array(waiterData.labels.length - indexOfMorningEnd).fill(eveningMax);
      waiterData.extendedData[weekday] = newMorning.concat(newEvening);
    });
    this.waitersAtTimeSlots = waiterData;
  }

  drawQosConcurrentTables() {
    this.qosConcurrentTables = this.dps.qosConcurrentTables(this.totalQosFilters);
  }

  async drawStaffingGraph() {
    const timeSlots = await this.simulation.getTimeSlots();
    this.staffingGraph = await this.dps.getDataAtTimeSlots(timeSlots, 'staffing', this.totalQosFilters);
    let totalScore = 0;
    let totalPoints = 0;
    Object.keys(this.staffingGraph.extendedData).forEach(weekday => {
      totalPoints += this.staffingGraph.extendedData[weekday].length;
      totalScore += this.staffingGraph.extendedData[weekday].reduce((a, b) => Math.abs(a) + Math.abs(b), 0);
    });

    this.staffingScore = 8 - Math.round(10 * (totalScore / totalPoints)) / 10;
    console.log('this.staffingScore ', this.staffingScore);
  }

  drawQosPie() {
    this.qosPie = this.dps.getQosPie();
    let totalVisitors = 0;
    let totalScore = 0;
    Object.keys(this.qosPie.data).forEach(label => {
      const score = Number(label);
      totalVisitors += this.qosPie.data[label];
      totalScore += (this.qosPie.data[label] * score);
    });
    this.qosScore = Math.round((totalScore / totalVisitors) * 10) / 10;
    // console.log('this.qosPie', this.qosPie)
    // console.log('totalVisitors', )
    // console.log('totalScore', totalScore)
  }

  changeTableOccupancyWeekday() {
    console.log('tableOccupancyWeekday', this.tableOccupancyWeekday);
  }

  openFilter() {
    // if(!this.filterStatus) this.filterStatus = !this.filterStatus
    // this.filterStatus = !this.filterStatus
    // console.log('this.filterStatus', this.filterStatus)
    this.sidenav.toggle();
  }

  tabClick($event: number) {
    console.log($event);
    console.log('overviewTabs', this.overviewTabs.selectedIndex);
  }

  waiterSelected(waiterName) {
    // console.log('totalQosFilters', this.totalQosFilters.waiters[waiterName])
    this.totalQosFilters.waiters = [waiterName];
    this.pageFilterChange();
    this.overviewTabs.selectedIndex = 2;
  }

  selectProblemPoint(weekday, slot) {
    console.log('totalQosFilters', this.totalQosFilters);
    const min = slot.substring(0, 2);
    const max = slot.substring(6, 8);
    const slots = [];
    for (let x = Number(min); x < Number(max); x++) {
      console.log('x', x);
      if (x < 10) {
        slots.push('0' + String(x) + '-' + String(x + 1));
      } else {
        slots.push(String(x) + '-' + String(x + 1));
      }

    }
    this.totalQosFilters.weekdays = [weekday];
    this.totalQosFilters.slots = slots;
    this.pageFilterChange();
    this.tableOccupancyWeekday = weekday;
    console.log('slots', slots);
    // console.log('max', max)
    console.log('weekday, slot', weekday, slot);
  }

  openWaiterDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(EmployeeDialogContent, {
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      // if (result.event == 'Add') {
      //   // this.addRowData(result.data);
      // } else if (result.event == 'Update') {
      //   // this.updateRowData(result.data);
      // } else if (result.event == 'Delete') {
      //   // this.deleteRowData(result.data);
      // }
    });
  }

  resetFilterChange() {
    this.totalQosFilters = Object.assign({}, this.totalQosFilterCopy);
    this.pageFilterChange();
  }
}

@Component({
  selector: 'dialog-content',
  templateUrl: 'dialog-content.html',
})
export class EmployeeDialogContent {
  action: string;
  local_data: any;

  constructor(
      public dialogRef: MatDialogRef<EmployeeDialogContent>,
      // @Optional() is used to prevent error if no data is passed
      @Optional() @Inject(MAT_DIALOG_DATA) public data) {
    //  console.log(data);
    this.local_data = {...data};
    this.action = this.local_data.action;
  }

  doAction() {
    this.dialogRef.close({event: this.action, data: this.local_data});
  }

  closeDialog() {
    this.dialogRef.close({event: 'Cancel'});
  }

}
