import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { NgStrapiAuthConfig } from './ng-strapi-auth-config';
import { Subject, Observable } from 'rxjs';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class NgStrapiAuthService {
  private apiUrl: string = undefined;
  private authStateChangesSubject: Subject<boolean> = new Subject();
  private userChangesSubject: Subject<any> = new Subject();
  private jwtChangesSubject: Subject<string> = new Subject();
  private _user = undefined;
  private _jwt = undefined;

  public authStateChanges$: Observable<boolean>;
  public userChanges$: Observable<any>;
  public jwtChanges$: Observable<string>;
  public get user() {
    return this._user;
  }
  public set user(u) {
    this._user = u;
    this.userChangesSubject.next(this._user);
  }
  public get jwt() {
    return this._jwt;
  }
  public set jwt(t) {
    this._jwt = t;
    this.jwtChangesSubject.next(this._jwt);
  }
  public authenticated = false;

  constructor(
    @Inject('config') private config: NgStrapiAuthConfig,
    private httpClient: HttpClient,
    private router: Router
  ) {
    if (this.config && this.config.apiUrl) {
      this.apiUrl = this.config.apiUrl;
    } else {
      const err = '[NgStrapiAuth]: no api url provided';
      console.error(err);
      throw new Error('[NgStrapiAuth]: no api url provided');
    }

    this.authStateChanges$ = this.authStateChangesSubject.asObservable();
    this.userChanges$ = this.userChangesSubject.asObservable();
    this.jwtChanges$ = this.jwtChangesSubject.asObservable();
  }

  autoSignIn() {
    if (!this.apiUrl) {
      throw new Error('[NgStrapiAuth]: no api url provided');
    }

    const credentials = this.getSavedCredentials();

    if (credentials) {
      this.user = credentials.user;
      this.jwt = credentials.jwt;
      this.authenticated = true;
      this.authStateChangesSubject.next(this.authenticated);

      return this.user;
    } else {
      throw new Error('[NgStrapiAuth]: no user-profile auto signed in');
    }
  }

  async signIn(username: string, password: string) {
    if (!this.apiUrl) {
      throw new Error('[NgStrapiAuth]: no api url provided');
    }

    try {
      const res: any = await this.httpClient
        .post(this.apiUrl + '/auth/local', {
          identifier: username,
          password: password
        })
        .toPromise();
      this.user = res.user;
      if(!this.user.confirmed){
        return this.router.navigate(['authentication/confirm-email']);
      }

      this.jwt = res.jwt;
      this.authenticated = true;
      this.saveCredentials();
      this.authStateChangesSubject.next(this.authenticated);

      return this.user;
    } catch (err) {
      throw err;
    }
  }

  async signOut() {
    this.user = undefined;
    this.jwt = undefined;
    this.authenticated = false;
    this.unsaveCredentials();
    this.authStateChangesSubject.next(this.authenticated);
      location.reload(true);
    return true;
  }

  // async requestEmailConfirmation() {
  //   const res: any = await this.httpClient
  //       .post(this.apiUrl + '/auth/send-email-confirmation', {
  //         email: this.user.email,
  //       })
  //       .toPromise();
  // }

  async forgotPassword(email: string) {
    const res: any = await this.httpClient
        .post(this.apiUrl + '/auth/forgot-password', {
          email: email,
        })
        .toPromise();
    return res
  }

  async changePassword(code: string, password) {
    const res: any = await this.httpClient
        .post(this.apiUrl + '/auth/reset-password', {
          code: code,
          password: password,
          passwordConfirmation: password,
        })
        .toPromise();

    this.user = res.user;
    this.jwt = res.jwt;
    this.user = res.user;
    this.authenticated = true;
    this.saveCredentials();
    this.authStateChangesSubject.next(this.authenticated);

    return res;
  }

  async register(full_name: string, email: string, password: string) {
    if (!this.apiUrl) {
      throw new Error('[NgStrapiAuth]: no api url provided');
    }

    try {
      const res: any = await this.httpClient
        .post(this.apiUrl + '/auth/local/register', {
          username: email,
          email: email,
          full_name: full_name,
          password: password
        })
        .toPromise();

      this.user = res.user;
      this.jwt = res.jwt;


      this.user = res.user;
      this.saveCredentials();


      if(!this.user.confirmed){
        return this.router.navigate(['authentication/confirm-email']);
      } else {
        this.authenticated = true;
        this.authStateChangesSubject.next(this.authenticated);
      }

      return this.user;
    } catch (err) {
      throw err;
    }
  }

  async saveCredentials() {
    if (this.user) {
      localStorage.setItem('current-user-profile', JSON.stringify(this.user));
    }
    if (this.jwt) {
      localStorage.setItem('current-user-profile-jwt', JSON.stringify(this.jwt));
    }
  }

  async unsaveCredentials() {
    localStorage.removeItem('current-user-profile');
    localStorage.removeItem('current-user-profile-jwt');
  }

  private getSavedCredentials() {
    const user = localStorage.getItem('current-user-profile');
    const jwt = localStorage.getItem('current-user-profile-jwt');

    if (user && jwt) {
      return {
        user: JSON.parse(user),
        jwt: JSON.parse(jwt)
      };
    } else {
      return undefined;
    }
  }
}
