import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class RestaurantsService {



  public allRestaurants = [
      {
        name: 'Oxford Zilla',
        location: 'Oxford',
        id: '1231Oxford',
        path: 'Oxford',
        capacity: 1231,
        md: 'Elon Musk',
        opened:  '2007'
      },
    {
      name: 'Cambridge Zilla',
      location: 'Cambridge',
      id: 'cam123',
      path: 'Cambridge',
      capacity: 1231,
      md: 'Bill Gates',
      opened:  '2007'
    },
    ];


  private selectedRestaurantSource = new BehaviorSubject(this.allRestaurants[0]);
  selectedRestaurantData = this.selectedRestaurantSource.asObservable();

  private selectedDateSource = new BehaviorSubject(moment());
  selectedDateSourceData = this.selectedDateSource.asObservable();

  constructor() {}

  changeRestaurant(id) {
    this.selectedRestaurantSource.next(this.allRestaurants.find(x =>  x.id === id));
  }

  setDate(date) {
    this.selectedDateSource.next(date);
  }
}
