import { Routes } from '@angular/router';

import {OverviewComponent} from './overview/overview.component';

export const EyezillaChartsRoutes: Routes = [
    {
        path: 'info',
        component: OverviewComponent,
        data: {
            title: 'Restaurant Overview',
            urls: [
                { title: 'Overview', url: '/overview_dash' },
                { title: 'Overview' }
            ]
        }
    }
];
