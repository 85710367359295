import { Component, OnInit } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-service-terms',
  templateUrl: './service-terms.component.html',
  styleUrls: ['./service-terms.component.css']
})
export class ServiceTermsComponent implements OnInit {

  apiUrl = environment.apiUrl;
  loaded = false;
  ofertText;

  constructor(private httpClient: HttpClient) { }

  async ngOnInit() {
    const res: any = await this.httpClient
        .get(this.apiUrl + '/service-terms')
        .toPromise();
    this.ofertText = res.text;
    this.loaded = true;
    // console.log('res', res)
  }
}
