import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { CustomValidators  } from 'ngx-custom-validators';
import {HttpClient} from "@angular/common/http";
import {NgStrapiAuthService} from "../../services/strapi/auth/ng-strapi-auth.service";


@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {
  public form: FormGroup;
  constructor(private fb: FormBuilder, private router: Router, private httpClient: HttpClient,private authService: NgStrapiAuthService) {}

  ngOnInit() {
    // we need to check if there is an email set
    if(this.authService?.user?.email === undefined){
      this.router.navigate(['/login']);
    }

  }

  // async reSendConfirmation(){
    // const res: any = await this.httpClient
    //     .post(this.apiUrl + '/auth/local', {
    //       email: username,
    //       password: password
    //     })
    //     .toPromise();
  //   await this.authService.requestEmailConfirmation()
  // }
  // onSubmit() {
  //   this.router.navigate(['/authentication/login']);
  // }
}
