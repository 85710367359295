import {Injectable} from '@angular/core';
import DataFrame from 'dataframe-js';
import * as moment from 'moment';
import {DictService} from './dict.service';
import {SimulationService} from './simulation.service';
import {Md5} from 'ts-md5/dist/md5';
import * as localForage from 'localforage';

@Injectable({
  providedIn: 'root'
})
export class DataProcessingService {


  constructor(private dict: DictService, private simulation: SimulationService) {
  }
  //
  private simulationData: any;
  private dataframe: any = undefined;

  dfCache = {};

  qosDataframe = undefined;

  public setSimulationData(simulationData) {
    this.simulationData = simulationData;
  }

  public cleanUpData = () => {
    this.dfCache = {};
  }

  public async setDataFrame(restaurantInformation,  restaurantDate, filters, targetQos, targetQosFilter = [
    'Arrival to order',
    'Bill drop to payment',
    'Bill request to bill drop',
    'End of eating to plates collection',
    'Order to Mains',
    'Payment to leave',
    'Plates collection to upsell attempt',
    'Upsell attempt to Bill request'
  ], simulationData = this.simulationData) {
    return new Promise(async (resolve, reject) => {

      // if(this.dataframe === undefined){
      //
      // } else {
      //
      // }
      console.time('setDataFrame');
      console.log('restaurantInformation', restaurantInformation)
      const filterHash = String(Md5.hashStr(JSON.stringify([restaurantInformation, restaurantDate, filters, targetQos, targetQosFilter])));
      let localData = await localForage.getItem(filterHash)
      // console.log('localData', localData)
      // console.log('filterHash', filterHash)
      if(localData === null){


      this.dataframe = new DataFrame(simulationData);
      this.dataframe = this.dataframe.withColumn('occupancyPercent', (row) => row.get('restuarntOccpiedTables') / (row.get('totalTables') / 100));
      this.dataframe = this.dataframe.filter(row => this.validateFilter(row, filters));

      this.dataframe = this.dataframe.withColumn('newDeviation', (row) => {
        let deviation = 0;
        filters.qos.forEach((param) => {
          deviation += row.get(param) - targetQos[this.dict.simToInput[param]];
        });
        return Math.round(deviation * 100) / 100;
      });

      const tagetQosFilter = {};
      Object.keys(targetQos).map(inputKey => {
        if (targetQosFilter.indexOf(inputKey) > -1) {
          tagetQosFilter[this.dict.inputToSim[inputKey]] = targetQos[inputKey];
        }
      });

      this.dataframe = this.dataframe.withColumn('savingPotential', (row) => this.calculateSavingPotentialForTable(row, tagetQosFilter));

      this.dataframe = this.calculateDeviation(this.dataframe, targetQos, targetQosFilter, filters);
      // this.dataframe.show()
      const preSaveDf =  this.dataframe;
      await localForage.setItem(filterHash, this.dataframe.toJSON())
      } else {
      // localData = await localForage.getItem(filterHash + "123")
        // @ts-ignore
        this.dataframe = await DataFrame.fromJSON(new File([localData], "filename"));
        // this.dataframe.show()
      }
      // console.log(preSaveDf.diff(this.dataframe, 'newDeviation'))
      console.timeEnd('setDataFrame');
      resolve();
    });
  }

  public getWeekDays(simulationData = this.simulationData) {
    const df = new DataFrame(simulationData);
    return df.distinct('weekday').toArray().flat();
  }

  public getRestaurantStats(simulationData = this.simulationData) {
    const df = new DataFrame(simulationData);
    const row = df.select('totalTables', 'totalCapacity', 'Tables_sized_1', 'Tables_sized_2',
      'Tables_sized_3', 'Tables_sized_4', 'Tables_sized_5', 'Tables_sized_6', 'Tables_sized_7', 'Tables_sized_8').getRow(1);
    return row.toDict();
  }


  public getTotalTables(simulationData = this.simulationData) {
    let totals = [];

    const df = new DataFrame(simulationData);
    const weekDays = this.getWeekDays();

    totals = weekDays.map(weekday => {
      return df.where({weekday}).count();
    });

    return {labels: weekDays, data: totals, label: 'Total table turns'};

  }

  public getTotalGuests(simulationData = this.simulationData) {
    let totals = [];

    const df = new DataFrame(simulationData);
    const weekDays = this.getWeekDays();

    totals = weekDays.map(weekday => {
      return df.where({weekday}).stat.sum('groupSize');
    });

    return {labels: weekDays, data: totals, label: 'Total covers'};

  }

  public getDistinctOccupancy(simulationData = this.simulationData) {
    const df = new DataFrame(simulationData);
    const distinct = df.distinct('restaurantOccupancy').toArray().flat();
    return distinct;
  }

  waitingAtOccupancy(bucketSize = 20, param, simulationData = this.simulationData) {
    const totals = [];
    const newTotals = {};

    // let df = new DataFrame(simulationData);
    // df = df.withColumn('occupancyPercent', (row) => row.get('restuarntOccpiedTables') / (row.get('totalTables') / 100));
    const df = this.dataframe;
    const maxParam = Math.ceil(df.stat.max('occupancyPercent'));
    console.log('maxParam', maxParam);
    // const distinctOccupancy = this.getDistinctOccupancy();
    // distinctOccupancy.sort((a, b) => {
    //   return a - b;
    // });
    if (bucketSize > maxParam) {
      bucketSize = maxParam;
    }
    const incrementValue = Math.ceil(maxParam / bucketSize);
    const weekDays = this.dataframe.distinct('weekday').toArray().flat();
    const resultDistinctOcc = [];
    for (let i = 1; i <= bucketSize; i++) {

      const newMax = i * incrementValue;
      const oldMax = (i - 1) * incrementValue;
      resultDistinctOcc.push(newMax);

      for (const dayOfWeek of weekDays) {
        if (newTotals[dayOfWeek] === undefined) {
          newTotals[dayOfWeek] = [];
        }
        let meanTime = 0;
        if (param === 'length') {
          meanTime = df.filter(row => row.get('occupancyPercent') <= newMax &&
            row.get('occupancyPercent') > oldMax && row.get('weekday') === dayOfWeek).stat.mean('length');
        }

        if (param === 'concurentTables') {
          meanTime = df.filter(row => row.get('occupancyPercent') <= newMax &&
            row.get('occupancyPercent') > oldMax && row.get('weekday') === dayOfWeek).stat.mean('concurentTables');
        }

        if (isNaN(meanTime)) {
          meanTime = 0;
        }
        totals.push(meanTime);
        newTotals[dayOfWeek].push(meanTime);
      }

    }

    return {
      labels: resultDistinctOcc,
      data: totals,
      extendedData: newTotals,
      label: 'test'
    };
  }

  async getTargetQosDeviation(targetQos, filters = {
                                qos: ['arrivalToOrder',
                                  'billDropToPayment',
                                  'billReqToBillDrop',
                                  'endOfEatingToPlates',
                                  'mainToEndOfEating',
                                  'orderToMain',
                                  'paymentToLeave',
                                  'platesToUpsell',
                                  'upsellToBillReq', ],
                                occupancy: {min: 0, max: 100},
                                slots: {min: '09:00', max: '23:00'},
                                weekdays: [
                                  'Monday',
                                  'Tuesday',
                                  'Wednesday',
                                  'Thursday',
                                  'Friday',
                                  'Saturday',
                                  'Sunday'
                                ]
                              },
                              columnSplit = [
                                // 'weekdays',
                                // 'timeslots',
                                'occupancy'
                              ],
                              bucketSize = 10,
                              simulationData = this.simulationData) {

    console.time('getTargetQosDeviation');
    const labels = [];
    const data = [];
    const totals = {};

    // let df = new DataFrame(simulationData);
    //
    // df = df.withColumn('newDeviation', (row) => {
    //   let deviation = 0;
    //   filters.qos.forEach((param) => {
    //     deviation += row.get(param) - targetQos[this.dict.simToInput[param]];
    //   });
    //   return Math.round(deviation * 100) / 100;
    // });
    //
    // df = df.withColumn('occupancyPercent', (row) => row.get('restuarntOccpiedTables') / (row.get('totalTables') / 100));
    //
    // df = df.filter(row => this.validateFilter(row, filters));

    const df = this.dataframe;

    const splitType = columnSplit[0];
    let splitLabels = [];
    if (splitType === 'weekdays') {
      splitLabels = this.dataframe.distinct('weekday').toArray().flat();
    }
    if (splitType === 'timeslots') {
      splitLabels = await this.simulation.getTimeSlots();
    }
    if (splitType === 'occupancy') {
      splitLabels = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];
    }


    const maxParam = Math.ceil(df.stat.max('newDeviation'));
    const minParam = Math.floor(df.stat.min('newDeviation'));
    const range = maxParam + Math.abs(minParam);
    const incrementValue = Math.round((range / bucketSize) * 100) / 100;
    for (let i = 1; i <= bucketSize; i++) {
      const newMax = i * incrementValue - Math.abs(minParam);
      const oldMax = (i - 1) * incrementValue - Math.abs(minParam);
      splitLabels.forEach((label, index) => {
        if (!this.validateLabel(label, splitType, filters)) {
          return;
        }
        // this.validateFilter();
        if (totals[label] === undefined) {
          totals[label] = [];
        }
        let countVal = df.filter(row => {

          // if (!this.validateFilter(row, filters)) { return false; }

          if (row.get('newDeviation') <= newMax && row.get('newDeviation') > oldMax) {
            if (splitType === 'weekdays') {
              if (row.get('weekday') === label) {
                return true;
              }
            }

            if (splitType === 'timeslots') {
              const timeSlotStart = label;
              const timeSlotEnd = moment(timeSlotStart, 'HH:mm').add(1, 'hour').format('HH:mm');
              if (moment(row.get('startTime'), 'HH:mm').isBetween(moment(timeSlotStart, 'HH:mm'),
                moment(timeSlotEnd, 'HH:mm'), undefined, '[)')) {
                return true;

              }

            }

            if (splitType === 'occupancy') {
              let minOccupancy = label;
              let maxOccupancy = label + 10;
              if (minOccupancy === 0) {
                minOccupancy = -1;
              }
              if (maxOccupancy === 100) {
                maxOccupancy = 101;
              }
              if (row.get('occupancyPercent') > minOccupancy && row.get('occupancyPercent') <= maxOccupancy) {
                return true;
              }
            }


          }
          return false;
        }).count();
        if (isNaN(countVal)) {
          countVal = 0;
        }
        totals[label].push(countVal);
      });
      labels.push(Math.round(newMax * 100) / 100);
    }

    let totalCount = 0;
    Object.keys(totals).forEach(key => {
      totalCount += totals[key].reduce((a, b) => a + b, 0);
    });
    const newOnePercent = totalCount / 100;
    Object.keys(totals).forEach(key => {
      // const onePercent = (totals[key].reduce((a, b) => a + b, 0))/100;
      const newTotals = totals[key].map(value => {
        return value / newOnePercent;
      });
      totals[key] = newTotals;
    });
    console.timeEnd('getTargetQosDeviation');
    return {
      labels,
      totals,
    };
  }

  async getAverageQoS(simulationData = this.simulationData) {
    const result = {};
    const keys = ['arrivalToOrder', 'orderToMain', 'mainToEndOfEating', 'endOfEatingToPlates', 'platesToUpsell', 'upsellToBillReq', 'billReqToBillDrop', 'billDropToPayment', 'paymentToLeave'];
    const df = this.dataframe;
      // new DataFrame(simulationData);

    keys.forEach(key => {
      result[key] = Math.round(df.stat.mean(key) * 100) / 100;
    });
    return result;
  }

  getSummary() {

  }

  getWeekSummary(simulationData = this.simulationData) {
    const result = {
      totalGuests: 0,
      totalTableCovers: 0,
      bestDay: undefined,
      bestDayGuests: 0,
      bestDayTableCovers: 0,
      worstDay: undefined,
      worstDayGuests: Infinity,
      worstDayTableCovers: 0,
    };

    const df = new DataFrame(simulationData);
    result.totalGuests = df.stat.sum('groupSize');
    result.totalTableCovers = df.count();

    const totalGuests = this.getTotalGuests();
    const totalTables = this.getTotalTables();
    // let bestDay = {day: 0, guests: 0, tables: 0};
    // let worstDay = {day: 0, guests: Infinity, tables: 0};
    totalGuests.labels.forEach((value, index) => {
      if (result.bestDayGuests < totalGuests.data[index]) {
        result.bestDay = value;
        result.bestDayGuests = totalGuests.data[index];
        result.bestDayTableCovers = totalTables.data[index];
      }
      if (result.worstDayGuests > totalGuests.data[index]) {
        result.worstDay = value;
        result.worstDayGuests = totalGuests.data[index];
        result.worstDayTableCovers = totalTables.data[index];
      }
    });

    return result;
  }
  async getDataAtTimeSlots(timeSlots, param, filters, simulationData = this.simulationData) {
    console.time('getDataAtTimeSlots');
    const results = {
      labels: [],
      extendedData: {}
    };

    // let df = new DataFrame(simulationData);
    // df = df.withColumn('occupancyPercent', (row) => row.get('restuarntOccpiedTables') / (row.get('totalTables') / 100));
    // df = df.filter(row => this.validateFilter(row, filters));
    const filterHash = String(Md5.hashStr(JSON.stringify(filters)));

    if (this.dfCache[filterHash] === undefined) { this.dfCache[filterHash] = {}; }

    const df = this.dataframe;

    const weekDays = this.dataframe.distinct('weekday').toArray().flat();
    timeSlots.forEach(timeSlotStart => {
      const timeSlotEnd = timeSlotStart + 1 + ':00';
      timeSlotStart = timeSlotStart + ':00';

      results.labels.push(timeSlotStart);

      let tempSlot;
      if (this.dfCache[filterHash][timeSlotStart + timeSlotEnd] === undefined) {
        this.dfCache[filterHash][timeSlotStart + timeSlotEnd] = {};
        tempSlot = df.filter(row => moment(row.get('startTime'), 'HH:mm').isBefore(moment(timeSlotEnd, 'HH:mm'))
          && moment(row.get('startTime'), 'HH:mm').isAfter(moment(timeSlotStart, 'HH:mm')));
      }


      weekDays.forEach(weekDay => {
        if (this.dfCache[filterHash][timeSlotStart + timeSlotEnd][weekDay] === undefined) {
          this.dfCache[filterHash][timeSlotStart + timeSlotEnd][weekDay] = tempSlot.filter(row => row.get('weekday') === weekDay);
        }

        if (results.extendedData[weekDay] === undefined) {
          results.extendedData[weekDay] = [];
        }

        let countVal = 0;
        if (param === 'length') {
          try {
            countVal = this.dfCache[filterHash][timeSlotStart + timeSlotEnd][weekDay].stat.mean('length');
          } catch (e) {
            countVal = 0;
          }

        }
        if (param === 'concurentTables') {
          try {
            countVal = this.dfCache[filterHash][timeSlotStart + timeSlotEnd][weekDay].stat.mean('concurentTables');
          } catch (e) {
            countVal = 0;
          }

        }
        if (param === 'occupancyCount') {
          try {
            countVal = this.dfCache[filterHash][timeSlotStart + timeSlotEnd][weekDay].stat.max('occupancyPercent');
          } catch (e) {
            countVal = 0;
          }

        }

        if (param === 'deliveredQos') {
          try {
            countVal = this.dfCache[filterHash][timeSlotStart + timeSlotEnd][weekDay].stat.mean('deliveredQos');
          } catch (e) {
            countVal = 0;
          }
        }

        if (param === 'waitersNum') {
          try {
            countVal = this.dfCache[filterHash][timeSlotStart + timeSlotEnd][weekDay].distinct('waiterId').count();
          } catch (e) {
            countVal = 0;
          }
        }

        if (param === 'staffing') {
          try {
            countVal = (this.dfCache[filterHash][timeSlotStart + timeSlotEnd][weekDay].stat.mean('restuarntOccpiedTables') / 5) -
              this.dfCache[filterHash][timeSlotStart + timeSlotEnd][weekDay].distinct('waiterId').count();
          } catch (e) {
            countVal = 0;
          }
        }


        if (isNaN(countVal)) {
          countVal = 0;
        }
        results.extendedData[weekDay].push(Math.round(countVal * 100) / 100);

      });
    });

    console.timeEnd('getDataAtTimeSlots');
    // console.log('results', JSON.stringify(results));
    return results;

  }

  getQosStDev(filter, targetQos, simulationData = this.simulationData) {
    console.time('getQosStDev1');
    const result = {};
    const keys = ['arrivalToOrder', 'orderToMain', 'endOfEatingToPlates', 'platesToUpsell', 'upsellToBillReq', 'billReqToBillDrop', 'billDropToPayment', 'paymentToLeave'];
    // let df = new DataFrame(simulationData);
    // df = df.withColumn('occupancyPercent', (row) => row.get('restuarntOccpiedTables') / (row.get('totalTables') / 100));
    // df = df.filter(row => this.validateFilter(row, filter));
    const df = this.dataframe;
    // df = df.filter(row => row.get);
    // filter the ones that are larges than target
    // get mean of the one that are lager
    // calculate the pecent from mean - target
    keys.forEach(key => {
      console.log('this.dict.simToInput[key]', this.dict.simToInput[key])
      result[key] = Math.round(df.stat.mean(this.dict.simToInput[key]) * 100) / 100;
    });
    console.timeEnd('getQosStDev1');
    return result;

  }

  calculateSavingPotentialForTable(visit, tagetQosFilter) {
    let savingPotential = 0;
    Object.keys(tagetQosFilter).map(qos => {
      let value = visit.get(qos) - tagetQosFilter[qos];
      if (value <= 0) {
        value = 0;
      }
      savingPotential += value;
    });
    return savingPotential;
  }

  getTablesOccupancy(targetQos, globalFilter, targetQosFilterInput = [
    'Arrival to order',
    'Bill drop to payment',
    'Bill request to bill drop',
    'End of eating to plates collection',
    'Order to Mains',
    'Payment to leave',
    'Plates collection to upsell attempt',
    'Upsell attempt to Bill request'
  ],                 simulationData = this.simulationData) {
    console.time('getTablesOccupancy');
    const tagetQosFilter = {};
    Object.keys(targetQos).map(inputKey => {
      if (targetQosFilterInput.indexOf(inputKey) > -1) {
        tagetQosFilter[this.dict.inputToSim[inputKey]] = targetQos[inputKey];
      }
    });
    // let df = new DataFrame(simulationData);
    // df = df.withColumn('occupancyPercent', (row) => row.get('restuarntOccpiedTables') / (row.get('totalTables') / 100));
    // df = df.filter(row => this.validateFilter(row, globalFilter));
    //
    // df = df.withColumn('savingPotential', (row) => this.calculateSavingPotentialForTable(row, tagetQosFilter));
    const df = this.dataframe;

    const distinctTables = df.distinct('tableNumber').toArray('tableNumber');
    distinctTables.sort((a, b) => a - b);

    const tableVisits = {};
    distinctTables.forEach(tableNumber => {
      const visits = df.filter(row => row.get('tableNumber') === tableNumber).select('startTime', 'endTime', 'weekday', 'savingPotential').toArray();
      // console.log("visits", visits)
      // visits.sort((a, b) => moment(a[0], 'HH:mm').isAfter(moment(b[0], 'HH:mm')))
      // console.log("visits", visits)

      tableVisits[tableNumber] = visits;
      // tableVisits[tableNumber];
    });

    const byWeek = {};
    Object.keys(tableVisits).forEach(tableNumber => {
      tableVisits[tableNumber].forEach(visits => {
        if (byWeek[visits[2]] === undefined) {
          byWeek[visits[2]] = {};
        }
        if (byWeek[visits[2]][tableNumber] === undefined) {
          byWeek[visits[2]][tableNumber] = [];
        }
        byWeek[visits[2]][tableNumber].push([visits[0], visits[1], visits[3]]);
      });
    });
    console.timeEnd('getTablesOccupancy');
    return byWeek;
  }

  async getTimeSlotsOfOccupancy(timeSlots, minOccupancy, filters, simulationData = this.simulationData) {
    const data = await this.getDataAtTimeSlots(timeSlots, 'occupancyCount', filters);
    const result = {};
    // console.log('data', data.extendedData);

    Object.keys(data.extendedData).map((weekday) => {
      data.extendedData[weekday].map((occupancy, index) => {
        if (occupancy >= minOccupancy) {
          if (result[weekday] === undefined) {
            result[weekday] = [];
          }
          const timeSlot = data.labels[index];

          result[weekday].push(timeSlot);
        }
      });

    });
    return result;
  }

  async calculateSavingPotential(timeSlots, tableOccupancy, targetQos, filters) {
    // console.log('tableOccupancy', tableOccupancy);
    // console.log('targetQos', targetQos.Total);
    console.time('calculateSavingPotential');
    const busySlots = await this.getTimeSlotsOfOccupancy(timeSlots, 90, filters);
    const busyGaps = {};
    Object.keys(busySlots).forEach(weekday => {
      busyGaps[weekday] = [];
      busySlots[weekday].forEach(slot => {
        if (busyGaps[weekday].length === 0) {
          busyGaps[weekday].push([slot, moment(slot, 'HH:mm').add(1, 'hour').format('HH:mm')]);
        } else {
          const currentLast = busyGaps[weekday][busyGaps[weekday].length - 1][1];
          const isSame = moment(busyGaps[weekday][busyGaps[weekday].length - 1][1], 'HH:mm').isSame(moment(slot, 'HH:mm'));
          if (isSame) {
            //  consecutive slot
            busyGaps[weekday][busyGaps[weekday].length - 1][1] = moment(slot, 'HH:mm').add(1, 'hour').format('HH:mm');
          } else {
            //  slot break
            busyGaps[weekday].push([slot, moment(slot, 'HH:mm').add(1, 'hour').format('HH:mm')]);
          }
        }
      });
    });
    // console.log('busyGaps', busyGaps);
    // console.log('busySlots', busySlots);
    const savingPotential = {};
    const countedVisits = [];
    Object.keys(tableOccupancy).forEach(weekday => {
      if (Object.keys(busyGaps).indexOf(weekday) > -1) {
        if (savingPotential[weekday] === undefined) {
          savingPotential[weekday] = {};
        }
        Object.keys(tableOccupancy[weekday]).forEach(tableNo => {
          tableOccupancy[weekday][tableNo].forEach(slot => {
            // console.log('slot', slot)
            // console.log('tableOccupancy[weekday][tableNo]', tableOccupancy[weekday][tableNo])
            const start = slot[0];
            const end = slot[1];
            const saving = slot[2];
            const uniqueSign = weekday + String(tableNo) + start + end;
            if (countedVisits.indexOf(uniqueSign) > -1) {
              return;
            }


            busyGaps[weekday].forEach(slotGap => {
              const isBetween = moment(start, 'HH:mm').isBetween(moment(slotGap[0], 'HH:mm'),
                moment(slotGap[1], 'HH:mm'), undefined, '[]') ||
                moment(end, 'HH:mm').isBetween(moment(slotGap[0], 'HH:mm'),
                  moment(slotGap[1], 'HH:mm'), undefined, '[]');
              if (isBetween) {
                countedVisits.push(uniqueSign);
                if (savingPotential[weekday][tableNo] === undefined) {
                  savingPotential[weekday][tableNo] = {};
                }
                if (savingPotential[weekday][tableNo][slotGap[0] + '-' + slotGap[1]] === undefined) {
                  savingPotential[weekday][tableNo][slotGap[0] + '-' + slotGap[1]] = 0;
                }
                savingPotential[weekday][tableNo][slotGap[0] + '-' + slotGap[1]] += saving;
              }
            });
          });
        });
      }
      // tableOccupancy[weekday]
    });
    // console.log('savingPotential', savingPotential);
    const finalResult = {};
    Object.keys(savingPotential).forEach(weekday => {

      Object.keys(savingPotential[weekday]).forEach(tableNo => {
        Object.keys(savingPotential[weekday][tableNo]).forEach(timeSlot => {
          const potential = Math.trunc(savingPotential[weekday][tableNo][timeSlot] / targetQos.Total);
          if (potential >= 1) {
            if (finalResult[weekday] === undefined) {
              finalResult[weekday] = {};
            }
            if (finalResult[weekday][timeSlot] === undefined) {
              finalResult[weekday][timeSlot] = 0;
            }
            finalResult[weekday][timeSlot] += potential;
          }
        });

      });
    });
    console.timeEnd('calculateSavingPotential');
    // console.log('finalResult', finalResult);
    return finalResult;
  }

  calculateDeviation(df, targetQos, filterQos, filters) {
    let newDf = df.withColumn('occupancyPercent', (row) => row.get('restuarntOccpiedTables') / (row.get('totalTables') / 100));
    const qosPercentage = {};
    const singlePercent = (targetQos.Total - targetQos['Mains to end fo eating']) / 100;
    filterQos.forEach(qosName => {
      qosPercentage[qosName] = Math.round((targetQos[qosName] / singlePercent) * 100) / 100;
      console.log('qosName', qosName)
      newDf = newDf.withColumn(qosName, (row) => {
        const singlePrecentDev = targetQos[qosName] / 100;
        const visitTime = row.get(this.dict.inputToSim[qosName]);
        const deviationPercent = (visitTime - targetQos[qosName]) / singlePrecentDev;
        return deviationPercent;
      });
    });


    newDf = newDf.withColumn('deliveredQos', (row) => {
      let deviation = 0;
      filters.qos.forEach((param) => {
        const value = qosPercentage[this.dict.simToInput[param]] + (row.get(param) * (qosPercentage[this.dict.simToInput[param]] / 100));
        if (isNaN(value)) {
          return;
        }
        deviation += value;
      });
      return Math.round(100 * (10 - (deviation - 105))) / 100;
    });
    // newDf.show()
    return newDf;
  }

  getQosFrameCopy(targetQos = undefined, filterQos = undefined, filters = undefined, simulationData = this.simulationData) {
    if (this.qosDataframe === undefined) {
      this.qosDataframe = new DataFrame(simulationData);
      this.qosDataframe = this.calculateDeviation(this.qosDataframe, targetQos, filterQos, filters);
    }
    return this.qosDataframe.rename('waiterId', 'waiterId');
  }

  async getWaitersStats(filters, targetQos, filterQos = [
    'Arrival to order',
    'Bill drop to payment',
    'Bill request to bill drop',
    'End of eating to plates collection',
    'Order to Mains',
    'Payment to leave',
    'Plates collection to upsell attempt',
    'Upsell attempt to Bill request'
  ], simulationData = this.simulationData) {


    let df = this.getQosFrameCopy(targetQos, filterQos, filters);
    df = df.filter(row => this.validateFilter(row, filters));

    const waitersId = df.distinct('waiterId').toArray('waiterId');
    const waiterWorkdays = await this.simulation.getWaiterHours(df.distinct('weekday').toArray('weekday'))
    const result = waitersId.map(waiterId => {
      const waiterDf = df.filter(row => row.get('waiterId') === waiterId);
      const workDays = waiterDf.distinct('weekday').toArray('weekday');

      const info = {
        id: waiterId,
        name: this.dict.waiterNames[waiterId],
        averageWaiting: Math.round(100 * waiterDf.stat.mean('length')) / 100,
        workDays: waiterWorkdays[waiterId],
        averageConcurentTables: Math.round(100 * waiterDf.stat.mean('concurentTables')) / 100,
        averageDeviation: Math.round(100 * waiterDf.stat.mean('deliveredQos')) / 100,
      };
      return info;
    });
    return result;
    // console.log('result', result)
  }

  private validateLabel = (label, splitType, filter) => {
    if (splitType === 'weekdays') {
      // if (filter.weekdays.indexOf(label) > -1) {
        return true;
      // }
    }
    if (splitType === 'timeslots') {
      return true;
      // if (moment(label, 'HH:mm').isBefore(moment(filter.slots.max, 'HH:mm'))
      // && moment(label, 'HH:mm').isAfter(moment(filter.slots.min, 'HH:mm'))){
      //   return true;
      // }

    }
    if (splitType === 'occupancy') {
      return true;
      // if (label >= filter.occupancy.min && label <= filter.occupancy.max){
      //   return true;
      // }
    }
    return false;
  }

  private validateFilter(row: any, filters: any) {
    const visitTime = row.get('startTime');
    const occupancyAtStart = row.get('occupancyPercent');

    let occupancyValid = false;
    filters.occupancy.forEach(slot => {
      //  '20%-30%'
      let min = Number(slot.substring(0, 2));
      let max = Number(slot.substring(4, 6));
      if (min === 90) {
        max = 101;
      }
      if (isNaN(min)) {
        min = 0;
        max = 10;
      }

      if (occupancyAtStart >= min && occupancyAtStart < max) {
        return occupancyValid = true;
      }

    });

    let timeSlotValid = false;
    filters.slots.forEach(slot => {
      //  '20%-30%'
      const min = slot.substring(0, 2) + ':00';
      const max = slot.substring(3, 5) + ':00';
      if (moment(visitTime, 'HH:mm').isBetween(moment(min, 'HH:mm'), moment(max, 'HH:mm'), undefined, '[)')) {
        return timeSlotValid = true;
      }

    });


    if (filters.waiters.indexOf(this.dict.waiterNames[row.get('waiterId')]) < 0) {
      return false;
    }

    if (!occupancyValid) {

      return false;
    }
    if (!timeSlotValid) {
      return false;
    }

    if (filters.weekdays.indexOf(row.get('weekday')) < 0) {
      return false;
    }
    return true;
  }


  qosConcurrentTables(filters) {
    console.time('qosConcurrentTables');
    const results = {labels: [], data: [], label: 'Concurrent guests'};


    const df = this.dataframe;

    const concurrentGuests = this.dataframe.distinct('concurentTables').toArray().flat();
    concurrentGuests.sort((a, b) => a - b);
    concurrentGuests.forEach(guestsNo => {

      // results.data = []
      results.labels.push(guestsNo);
      let countVal = df.filter(row => row.get('concurentTables') === guestsNo).stat.mean('deliveredQos');

      if (isNaN(countVal)) {
        countVal = 0;
      }
      results.data.push(Math.round(countVal * 100) / 100);

      });


    console.timeEnd('qosConcurrentTables');
    return results;
  }

  getOverStaffing(){
  //  concurrent tables / 5 - waiters
  }

  getQosPie() {

    const results = {data: [], labels: []}
    const df = this.dataframe;

    for(let x = 0; x < 10; x++){
      const minQos = x;
      let maxQos = x + 1;
      results.labels.push(maxQos);
      if(maxQos === 10) maxQos += 1;
      let countVal = df.filter(row => row.get('deliveredQos') >= minQos && row.get('deliveredQos') < maxQos).stat.sum('groupSize');
      if (isNaN(countVal)) {
        countVal = 0;
      }

      results.data.push(countVal);
    }

    return results
  }
}
