import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import { CustomValidators  } from 'ngx-custom-validators';
import {NgStrapiAuthService} from "../../services/strapi/auth/ng-strapi-auth.service";

const password = new FormControl('', Validators.required);
const confirmPassword = new FormControl('', CustomValidators.equalTo(password));

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public form: FormGroup;
  private code: string;
  public msg;
  loading: boolean = false;

  constructor(private fb: FormBuilder, private router: Router,
              private authService: NgStrapiAuthService, private route: ActivatedRoute) {}

  ngOnInit() {

    this.code = this.route.snapshot.queryParamMap.get('code');
    if(this.code === null){
      return this.router.navigate(['login']);
    }

    this.form = this.fb.group({
      password: password,
      confirmPassword: confirmPassword,
    });
  }

  async onSubmit() {
    this.loading = true;
    try{
      let changeResult = await this.authService.changePassword(this.code, this.form.value.password)
      if(changeResult.jwt !== undefined){
        this.router.navigate(['dashboard']);
        this.loading = false;
      }
    }catch (e) {
      const errorMsg = e[0].messages[0].message;
      this.loading = false;
      // if(errorMsg === 'Incorrect code provided.'){
      //   return this.msg = 'Неверная ссылка для изменения пароля';
      // }
      return this.msg = errorMsg;
    }
    // this.router.navigate(['/authentication/login']);
  }
}
