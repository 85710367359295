import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
// import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes';
import * as ColorSchemes from 'chartjs-plugin-colorschemes';
import {ColorSchemesService} from '../../services/color-schemes.service';


@Component({
  selector: 'app-qos-deviation-chart',
  templateUrl: './qos-deviation-chart.component.html',
  styleUrls: ['./qos-deviation-chart.component.css']
})
export class QosDeviationChartComponent implements OnInit {

  @ViewChild('chart') chart;

  @Input()
  // tslint:disable-next-line:variable-name
  public set inputData(_data: any){
    this.data = _data;
    this.updateData();
  }
  data = undefined;

  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          beginAtZero: true,
          min: undefined,
          max: undefined
        },
        stacked: true,

      }],
      xAxes: [{
        stacked: true,
        ticks: {
          min: undefined,
          max: undefined
        }
      }]
    },
    plugins:{
      colorschemes: {
        scheme: 'tableau.HueCircle19',
        override: true
      // reverse: true,
      }
    }
  };
  chartLabels: Label[] = [];
  chartType: ChartType = 'bar';
  chartLegend = true;
  chartPlugins = [ColorSchemes];

  chartData: ChartDataSets[] = [];
  loadedComponent = true;

  constructor(public colorSchemes: ColorSchemesService) {
    // BaseChartDirective.registerPlugin(ColorSchemes);
  }

  ngOnInit(): void {

  }

  updateData(){
    if (this.data === undefined) { return; }
    // this.loadedComponent = false;
    console.log('this.data', this.data);
    this.chartLabels = this.data.labels;
    if(Object.keys(this.data.totals).length === 0){
      this.data.totals.Monday = [0];
    }
    // console.log('Object.keys(this.data.totals).length', Object.keys(this.data.totals).length)
    if (Object.keys(this.data.totals).length <= 7) {
      this.chartOptions.plugins.colorschemes.scheme = this.colorSchemes.colors.weekdays;
      // this.chartOptions.plugins.colorschemes.scheme = 'tableau.SunsetSunrise7';
      // this.chartOptions.plugins.colorschemes.scheme = 'tableau.JewelBright9';
      // this.chartOptions.plugins.colorschemes.scheme = 'brewer.RdYlBu7';
      // this.chartOptions.plugins.colorschemes.scheme = 'brewer.YlGnBu7';
      // this.chartOptions.plugins.colorschemes.scheme = 'tableau.HueCircle19';
      // this.chartOptions.plugins.colorschemes.reverse = true;
      this.chartOptions.plugins.colorschemes.reverse = false;
    }
    if (Object.keys(this.data.totals).length > 10) {
      this.chartOptions.plugins.colorschemes.scheme = 'tableau.HueCircle19';
      this.chartOptions.plugins.colorschemes.reverse = false;
    }
    if (Object.keys(this.data.totals).length === 10) {
      this.chartOptions.plugins.colorschemes.scheme = 'brewer.RdYlGn10';
      this.chartOptions.plugins.colorschemes.reverse = true;
    }
    this.chartData = [];

    const reverseBuild = {};
    // let index = 0;
    for (const key of Object.keys(this.data.totals)){
      // index++;
      // if(reverseBuild[key] === undefined) reverseBuild[key] = {};
      // if(reverseBuild[key][this.data.labels[index]] === undefined) reverseBuild[key][this.data.labels[index]] = [];
      // reverseBuild[key][this.data.labels[index]].push()
      // // const sumOfScores = this.data.totals[key].reduce((a, b) => a + b, 0);
      // // if(sumOfScores > maxY) maxY = sumOfScores;
      // // console.log('key', key)
      this.chartData.push({data: this.data.totals[key], label: key, hoverBackgroundColor: undefined});
    }
    if (this.chartOptions.scales.xAxes[0].ticks.suggestedMin === undefined){
      const minX = Math.min(...this.data.labels);
      const maxX = Math.max(...this.data.labels);
      const minY = 0;
      let maxY = 0;
      this.data.labels.forEach((slot, index) => {
        let slotTotal = 0;
        Object.keys(this.data.totals).forEach(weekday => {
          slotTotal += this.data.totals[weekday][index];
        });
        if (slotTotal > maxY) { maxY = Math.ceil(slotTotal); }
      });

      this.chartOptions.scales.xAxes[0].ticks.min = minX;
      this.chartOptions.scales.xAxes[0].ticks.max = maxX;
      this.chartOptions.scales.yAxes[0].ticks.min = minY;
      this.chartOptions.scales.yAxes[0].ticks.max = maxY;

    }
    // this.chart.chart.update();
    setTimeout(x => this.chartData = this.chartData);
    // this.loadedComponent = true;
  }
}
