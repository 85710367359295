import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import {Injectable, LOCALE_ID, NgModule} from '@angular/core';
import { RouterModule } from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { environment } from './../environments/environment';
import { MarkdownModule } from 'ngx-markdown';

import {EyezillaChartsModule} from './eyezilla_charts/eyezilla-charts.module';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { AppBreadcrumbComponent } from './layouts/full/breadcrumb/breadcrumb.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {LoginComponent} from './login/login.component';
import { ClipboardModule} from 'ngx-clipboard';

import {NgStrapiAuthModule} from './services/strapi/auth/ng-strapi-auth.module';
import {NgStrapiJwtInterceptorService} from './services/strapi/auth/ng-strapi-jwt-interceptor.service';
import {NgStrapiErrorInterceptor} from './services/strapi/auth/ng-strapi-error-interceptor.service';

// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import {EyezillaChartsModule} from "./eyezilla_charts/eyezilla-charts.module";

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';


import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { GraphQLModule } from './graphql.module';

// import { CampaignsListComponent } from './campaign/campaigns-list.component';
// import { CampaignCreateComponent } from './campaign/create/campaign-create.component';
// import { PendingCampaignViewComponent } from './campaign/view/pending-campaign-view/pending-campaign-view.component';
// import {Unused_influencerModule} from "./influencer/influencer.module";
// import { CampaignAddInfluencerComponent } from './campaign/view/add-influencer/campaign-add-influencer.component';
// import { RegisterInfluencerComponent } from './campaign/external/register-influencer/register-influencer.component';
// import {ClipboardModule} from "@angular/cdk/clipboard";
// import {InfluencerViewComponent} from "./influencer/influencer-view/influencer-view.component";
// import { RunningCampaignViewComponent } from './campaign/view/running-campaign-view/running-campaign-view.component';
// import { CampaignViewerComponent } from "./campaign/view/campaign-viewer.component";
// import { EmployeeDialogContent} from "./campaign/view/pending-campaign-view/pending-campaign-view.component";
// import { CampaignDialogComponent } from './campaign/view/campaign-dialog/campaign-dialog.component';
import { UserComponent } from './dashboard/user/user.component';

import {RegisterComponent} from './login/register/register.component';
import {ForgotComponent} from './login/forgot/forgot.component';
import {ConfirmEmailComponent} from './login/confirm-email/confirm-email.component';
import {ChangePasswordComponent} from './login/change-password/change-password.component';
import { FooterComponent } from './login/footer/footer.component';
import { PrivacyComponent } from './terms/privacy/privacy.component';
import { ServiceTermsComponent } from './terms/service-terms/service-terms.component';

import { EmptyComponent } from './layouts/empty/empty.component';
import { GoogleComponent } from './login/external/google/google.component';
import { ServiceWorkerModule } from '@angular/service-worker';



// import { CalendarModule, DateAdapter, CalendarDateFormatter } from 'angular-calendar';
// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';

import {DateAdapter, NativeDateAdapter} from '@angular/material/core';
import {Platform} from '@angular/cdk/platform';

registerLocaleData(localeRu);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@Injectable()
export class RuDateAdapter extends NativeDateAdapter {
  constructor() {
    super('ru', new Platform());
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}

@NgModule({
  declarations: [
  AppComponent,
  FullComponent,

  AppHeaderComponent,
  AppBlankComponent,
  SpinnerComponent,
  AppSidebarComponent,
    AppBreadcrumbComponent,
      LoginComponent,
    UserComponent,

    RegisterComponent,
    ForgotComponent,
    ConfirmEmailComponent,
    ChangePasswordComponent,
    FooterComponent,
    PrivacyComponent,
    ServiceTermsComponent,
    EmptyComponent,
    GoogleComponent,


  ],
  imports: [
    ClipboardModule,
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    HttpClientModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule.forRoot(AppRoutes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
    MarkdownModule.forRoot(),
    EyezillaChartsModule,
    // CalendarModule.forRoot({
    //   provide: DateAdapter,
    //   useFactory: adapterFactory
    // }),
    // Unused_influencerModule,
    NgApexchartsModule,
    GraphQLModule,
    NgStrapiAuthModule.forRoot({
          apiUrl: environment.apiUrl
        }
    ),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // Unused_influencerModule
  ],
  providers: [
    // { provide: LOCALE_ID, useValue: 'ru'},
    {provide: DateAdapter, useClass: RuDateAdapter},
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
      { provide: HTTP_INTERCEPTORS, useClass: NgStrapiJwtInterceptorService, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: NgStrapiErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // constructor(private dateAdapter: DateAdapter<Date>) {
  //   this.dateAdapter.setLocale('pt-BR');
  // }
}
