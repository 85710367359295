import {Component, Input, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import * as ColorSchemes from 'chartjs-plugin-colorschemes';

@Component({
  selector: 'app-totals-stack',
  templateUrl: './totals-stack.component.html',
  styleUrls: ['./totals-stack.component.css']
})
export class TotalsStackComponent implements OnInit {
  @Input()
  // tslint:disable-next-line:variable-name
  public set inputData(_data: any){
    // console.log('_data', _data)
    this.data = _data;
    this.updateData();
  }
  data = undefined;

  barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          beginAtZero: true
        }
      },
        //   {
        //   id: 'B',
        //   type: 'linear',
        //   position: 'right',
        //   ticks: {
        //     beginAtZero: true
        //   }
        // }
      ]
    },
    plugins:{
      colorschemes: {
        scheme: 'tableau.ClassicCyclic13',
        override: true
        // reverse: true,
      }
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
  };
  barChartLabels: Label[] = [];
  barChartType: ChartType = 'line';
  barChartLegend = true;
  barChartPlugins = [ColorSchemes];

  barChartData: ChartDataSets[] = [];
  loadedComponent: boolean = false;

  constructor() {
  }

  async ngOnInit() {

  }

  updateData() {
    console.log('this.data', this.data)
    if (this.data.a.length === 0) {
      return;
    }
    console.log("Call")
    this.loadedComponent = false;
    // console.log('inputData', this.data)
    // this.barChartOptions.plugins.colorschemes.scheme =  'tableau.ClassicCyclic13';
    this.barChartData[0] = this.data.a;
    this.barChartData[0].yAxisID = 'A';
    this.barChartLabels = this.data.a.labels;

    if (this.data.b !== undefined) {
      this.barChartData[1] = this.data.b;
      this.barChartData[1].yAxisID = 'B';

      this.barChartOptions.scales.yAxes.push(
          {
            id: 'B',
            type: 'linear',
            position: 'right',
            ticks: {
              beginAtZero: true
            }
          });
    }
    //   {
    //   id: 'B',
    //   type: 'linear',
    //   position: 'right',
    //   ticks: {
    //     beginAtZero: true
    //   }
    // }


    // this.loadedComponent = true
    setTimeout(x => this.loadedComponent = true);
    // { return; }

  }

}
