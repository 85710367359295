import { Routes } from '@angular/router';
import { AuthGuard } from './services/strapi/auth/ng-strapi-auth.guard';
import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './login/login.component';

import {UserComponent} from './dashboard/user/user.component';
import {OverviewComponent} from './eyezilla_charts/overview/overview.component';
import {RegisterComponent} from './login/register/register.component';
import {ForgotComponent} from './login/forgot/forgot.component';
import {ConfirmEmailComponent} from './login/confirm-email/confirm-email.component';
import {ChangePasswordComponent} from './login/change-password/change-password.component';
import {PrivacyComponent} from './terms/privacy/privacy.component';
import {ServiceTermsComponent} from './terms/service-terms/service-terms.component';
import {EmptyComponent} from './layouts/empty/empty.component';
import {GoogleComponent} from './login/external/google/google.component';
import {NgStrapiTrainerAuthGuard} from './services/strapi/auth/ng-strapi-trainer-auth.guard';
// import {TrainerComponent} from "./dashboard/trainer/trainer.component";


export const AppRoutes: Routes = [
    {
        path: '',
        component: FullComponent,
        children: [
            {
                path: '',
                redirectTo: 'demo',
                pathMatch: 'full'
            },
            {
                path: 'dashboard',
                component: UserComponent,
                data: {
                    title: 'Dashboard',
                    urls: [
                        { title: '' , url: 'view'}
                    ]
                },
            },
            {
                path: 'demo',
                component: OverviewComponent,
                data: {
                    title: 'Demo',
                    urls: [
                        { title: '' , url: 'view'}
                    ]
                },
            },
            // {
            //     path: 'session-list',
            //     component: SessionListComponent,
            //     data: {
            //         title: 'Тренировки',
            //         urls: [
            //             { title: '' , url: 'view'}
            //         ]
            //     },
            // },
            // {
            //     path: 'subscription/list',
            //     component: SubscriptionListComponent,
            //     data: {
            //         title: 'Subscription List',
            //         urls: [
            //             { title: 'List' , url: 'view'}
            //         ]
            //     },
            // },
            // {
            //     path: 'subscription',
            //     component: BuySubscriptionComponent,
            //     data: {
            //         title: 'Подписка',
            //         urls: [
            //             { title: '' , url: 'view'}
            //         ]
            //     },
            // },
            // {
            //     path: 'profile',
            //     component: UserProfileComponent,
            //     data: {
            //         title: 'Мой Профиль',
            //         urls: [
            //             { title: '' , url: 'view'}
            //         ]
            //     },
            // },
            // {
            //     path: 'campaign/:id',
            //     component: CampaignViewerComponent,
            //     data: {
            //         title: 'Campaign',
            //         urls: [
            //             { title: 'Campaigns List' , url: '/campaign'},
            //             { title: 'Campaign View' }
            //         ]
            //     }
            // },

        ]
    },
    // {
    //     path: 'session-view',
    //     component: AppBlankComponent,
    //     canActivate: [AuthGuard],
    //     children: [
    //         {
    //             path: ':id',
    //             component: SessionViewComponent,
    //             // redirectTo: "login",
    //             // pathMatch: 'full'
    //         },
    //     ]
    // },
    // {
    //     path: 'booking/:type',
    //     canActivate: [AuthGuard],
    //     component: BookSessionComponent,
    // },
    // {
    //     path: 'client-questionnaire',
    //     canActivate: [AuthGuard],
    //     component: AppBlankComponent,
    //     children: [
    //         {
    //             path: '',
    //             component: ClientQuestionnaireComponent,
    //         },
    //     ]
    // },
    // {
    //     path: 'redirect',
    //     canActivate: [AuthGuard],
    //     component: AppBlankComponent,
    //     children: [
    //         {
    //             path: 'session-selector',
    //             component: BookSessionSelectorComponent,
    //             // redirectTo: "login",
    //             // pathMatch: 'full'
    //         },
    //     ]
    //     // redirectTo: "login",
    //     // pathMatch: 'full'
    // },
    // {
    //     path: 'book-session',
    //     canActivate: [AuthGuard],
    //     component: BookSessionSelectorComponent,
    //     // redirectTo: "login",
    //     // pathMatch: 'full'
    // },
    // {
    //     path: 'trainer',
    //     canActivate: [NgStrapiTrainerAuthGuard],
    //     component: FullTrainerComponent,
    //     children: [
    //         {
    //             path: '',
    //             redirectTo: 'sessions',
    //             pathMatch: 'full'
    //         },
    //         {
    //             path: 'sessions',
    //             component: SessionsComponent,
    //             data: {
    //                 title: 'Тренировки',
    //                 urls: [
    //                     { title: '' , url: 'view'}
    //                 ]
    //             },
    //         }
    //     ]
    // },


    {
        path: 'auth/external/google',
        component: GoogleComponent,
    },
    {
        path: 'auth/external/facebook',
        component: GoogleComponent,
    },
    {
        path: '',
        component: EmptyComponent,
        children: [
            {
                path: 'login',
                component: LoginComponent,
            },
            {
                path: 'privacy',
                component: PrivacyComponent,
            },
            {
                path: 'terms-of-service',
                component: ServiceTermsComponent,
            },

            {
                path: 'authentication/register',
                component: RegisterComponent,
            },
            {
                path: 'authentication/forgot',
                component: ForgotComponent,
            },
            {
                path: 'authentication/confirm-email',
                component: ConfirmEmailComponent,
            },
            {
                path: 'authentication/change-password',
                component: ChangePasswordComponent,
            },
        ]
    },

    {
        path: '**',
        redirectTo: '/demo',
        // redirectTo: "login",

    },
];
