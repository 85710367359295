import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {GoogleChartInterface} from 'ng2-google-charts';

@Component({
  selector: 'app-timeline-chart',
  templateUrl: './timeline-chart.component.html',
  styleUrls: ['./timeline-chart.component.css']
})


export class TimelineChartComponent implements OnInit {
  @ViewChild('chart', {read: ElementRef, static: false}) elementView: ElementRef;
  chartHeight = 0;
  chartWidth = 0;
  public timelineChartData: GoogleChartInterface = {
    chartType: 'Timeline',
    dataTable: [
      ['Number', 'Label', 'style', 'From', 'To'],
    ],
    options: {
      width: this.chartWidth,
      height: this.chartHeight,
      showRowLabels: false,
      showBarLabels: false,
      timeline: {
        colors: ['red', '#004411'],
        // showRowLabels: true,
        colorByRowLabel: false,
        fontSize: 0,
        showRowLabels: false,
        showBarLabels: false,
        barLabelStyle: {fontSize: 0},
      }
    },
    // formatters:[
    //   {type: 'ColorFormat',
    //     columns:[1],
    //     options:{
    //     ranges:[{
    //       from: 2,
    //       to: 6,
    //       color: 'black',
    //       bgcolor: 'black'
    //     }]
    //   }}
    // ]

  };

  data = undefined;
  loadedComponent: any = false;

  constructor() {
    setInterval(() => {
      let chartHeight = this.elementView.nativeElement.clientHeight
      let chartWidth = this.elementView.nativeElement.clientWidth
      if (chartHeight !== this.chartHeight || chartHeight !== this.chartHeight) {
        this.chartHeight = chartHeight
        this.chartWidth = chartWidth
        this.updateData()
      }

    }, 500)
  }

  @Input()
  // tslint:disable-next-line:variable-name
  public set inputData(_data: any) {
    // console.log('_data', _data)
    this.data = _data;
    this.updateData();
  }

  OnInit(): void {


  }

  updateData() {
    if (this.data.occupancy === undefined) {
      return;
    }
    // let id = 0;
    // console.log(this.parent.clientHeight);

    this.loadedComponent = false;
    // return;
    // this.timelineChartData.dataTable = [];
    const tempData = [['Number', 'Label', 'From', 'To']];
    if (this.data.occupancy[this.data.filter] === undefined) return;
    Object.keys(this.data.occupancy[this.data.filter]).forEach(tableNumber => {
      // this.dataSource.processes.process.push({
      //   label: String(tableNumber),
      //     id: String(tableNumber)
      // })
      this.data.occupancy[this.data.filter][tableNumber].forEach(visit => {
        // id += 1;
        const endTime = moment(visit[1], 'HH:mm').isBetween(moment('00:00', 'HH:mm'),
            moment('5:00', 'HH:mm'), undefined, '[]') ?
            moment(visit[1], 'HH:mm').add(1, 'day').toDate() :
            moment(visit[1], 'HH:mm').toDate();
        // @ts-ignore

        // if(visit[3] > 15)
        // @ts-ignore
        tempData.push([String(tableNumber), (visit[2] > 15) ? '4' : '2', moment(visit[0], 'HH:mm').toDate(), endTime]);
        // tempData.push( [ String(tableNumber),'4', moment(visit[0], 'HH:mm').toDate(), endTime]);
        // this.dataSource.tasks.task.push(    {
        //   label: 'Occupied',
        //   processid: String(tableNumber),
        //   start: visit[0],
        //   end: visit[1],
        //   bordercolor: '#62B58D',
        //   color: '#62B58D',
        //   id: String(tableNumber)
        // })
      });
    });
    this.timelineChartData.dataTable = tempData;
    this.timelineChartData.options.width = this.chartWidth
    this.timelineChartData.options.height = this.chartHeight
    //     {
    //   width: this.chartWidth,
    //       height: this.chartHeight,
    // }

    setTimeout(x => this.loadedComponent = true);

    // console.log('this.timelineChartData', this.timelineChartData)
    // let ccComponent = this.timelineChartData.component;
    // let ccWrapper = ccComponent.wrapper;

    //force a redraw
    // ccComponent.draw();
    // this.timelineChartData.component.draw()

    // this.dataSource.tasks.task.push()
    // console.log('this.data', this.data);
    // this.chartLabels = this.data.labels;
    // this.chartData = [];
    // for (const key of Object.keys(this.data.totals)){
    //   this.chartData.push({data: this.data.totals[key], label: key});
    // }
  }

  ngOnInit(): void {
  }

}
