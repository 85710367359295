import {Injectable} from '@angular/core';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import * as localForage from 'localforage';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})


export class SimulationService {

  constructor(public http: HttpClient) {

  }


  static simParams = {
    waiter : {
      shifts: {
        morningStart: '10:00',
        morningEnd: '16:00',
        eveningStart: '16:00',
        eveningEnd: '23:00'
      },
      slowStart: false
    },
    kitchen: {
      alwaysFast: false
    },
    guests: {
      fastLunch: ['Sunday'],
      lateUpsellFastBill: false
    }
  };
  static  waiterParams = {};

  private workbook: Workbook;
  private simulationResult: any[];
  private previousSimulation: string;
  private simluationSource: string;

  async loadWb(source){
    const data = await this.http.get(source, {responseType: 'blob'}).toPromise();
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(data);
    const wb = await new Promise(((resolve, reject) => {
      fileReader.onload = () => {
        const arrayBuffer = fileReader.result;
        // @ts-ignore
        const unitArray = new Uint8Array(arrayBuffer);
        const arr = [];
        for (let i = 0; i !== unitArray.length; ++i) {
          arr[i] = String.fromCharCode(unitArray[i]);
        }
        const bstr = arr.join('');
        const finalXlsx = XLSX.read(bstr, {type: 'binary'});
        resolve(finalXlsx);
      };
    }));
    return wb;
  }

  public async loadData(source = 'Oxford', date) {
    console.log('load data', source, date)
    let sourcePath = 'assets/simulation_inputs/restaurants/' + source + '.xlsx';
    this.previousSimulation = await localForage.getItem('simulationResult_' + source + date);
    this.simluationSource = await localForage.getItem('simluationSource_' + source + date);

    if (this.previousSimulation === null || this.simluationSource !== sourcePath) {
      const wb = await this.loadWb(sourcePath);
      this.workbook = new Workbook(wb);
      const simulationResult = this.rerunSimulation();
      await localForage.setItem('simulationResult_' + source + date, JSON.stringify(simulationResult));
      await localForage.setItem('simluationSource_' + source + date, sourcePath);
      this.simulationResult = simulationResult;
    } else {
      this.simulationResult = JSON.parse(this.previousSimulation);
    }

    return this.simulationResult;
  }

  public async removeSimulationData(source = 'Oxford.xlsx', date) {
    // let sourcePath = 'assets/simulation_inputs/' + source;
    await localForage.removeItem('simulationResult_' + source + date);
    await localForage.removeItem('simluationSource_' + source + date);
  }

  public rerunSimulation() {
    let result = [];

    for (let i = 1; i < 8; i++) {
      const newWb = JSON.parse(JSON.stringify(this.workbook));
      const sim = new Simulation(newWb);

      const day = sim.generateDay(i);
      if (result.length === 0) {
        result = Object.assign([], day);
      } else {
        result = result.concat(day);
      }
    }
    console.log('result', result.length);
    return result;
  }

  private async verifyWorkbookExits(){
    if (this.workbook === undefined){
      const newWb = await this.loadWb(this.simluationSource);
      this.workbook = new Workbook(newWb);
    }
  }

  public async getTargetQoS(){
    await this.verifyWorkbookExits();
    const targetQoS = {};
    this.workbook.data['Target QoS'][0].map((value, index) => {
      targetQoS[value] = this.workbook.data['Target QoS'][1][index];
    });
    return targetQoS;
  }

  public async getTimeSlots(){
    await this.verifyWorkbookExits();
    const results = [];
    for (let i = 1; i < this.workbook.data['Slot simulation'].length - 2; i++) {

      results.push(this.workbook.data['Slot simulation'][i][0]);
    }
    return results;
  }

  public getShifts() {
    return SimulationService.simParams.waiter.shifts;
  }

  public async getWaiterHours(weekdays) {
    await this.verifyWorkbookExits();
    const results = {};
    for (let i = 1; i < this.workbook.data['Waiters Specs'].length - 1; i++) {
      results[this.workbook.data['Waiters Specs'][i][0]] = {};
      const weekStart = 14;
      weekdays.forEach((day, index) => {
        const status = this.workbook.data['Waiters Specs'][i][weekStart + index];
        if (status === 'off') return;
        // if(status === 'morning') {
        //   hours.start = SimulationService.simParams.waiter.shifts.morningStart;
        //   hours.end = SimulationService.simParams.waiter.shifts.morningEnd;
        // }
        //   if(status === 'evening'){
        //     hours.start = SimulationService.simParams.waiter.shifts.eveningStart;
        //     hours.end = SimulationService.simParams.waiter.shifts.eveningEnd;
        //   }
        results[this.workbook.data['Waiters Specs'][i][0]][day] = status;
      })
      // console.log('this.workbook.data[\'Waiters Specs\'][i]', this.workbook.data['Waiters Specs'][i])
      // results.push(this.workbook.data['Waiters Specs'][i][0]);
    }
    console.log('results', results)
    return results;
  }

}

class Workbook{
  readonly data: any;

  constructor(wb) {
    this.data = this.to_json(wb);
    console.log('this.data', this.data);
  }

  to_json(workbook){
    const result = {};
    workbook.SheetNames.forEach((sheetName) => {
      const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1});
      if (roa.length) { result[sheetName] = roa; }
    });
    return result;
  }
}


class Waiter{
  private id: any;
  private timeAddingParams: any;
  private qosDeviation: any;
  private tablesToHandle: any;
  private schedule: any;
  private qosDrop: any;
  private customers: any[];
  private currentWorkload: { tables: number; guests: number };
  private qosOnlyParams: { mainToCheckback: any };
  private debufs = {
    slowDays: [],
    quickUpsellNoAttentionAfter: false,
    slowParams: [],
    overwhelmed: {
      forgetToUpsell: false,
      slowOrdering: false,
      slowBill: false,
      fastPlates: false,
      quickPayment: false,
    }
  };
  constructor(params) {
    this.id = params[0];
    this.timeAddingParams = {
      arrivalToOrder: params[1],
      orderToMain: params[2],
      mainToEndOfEating: params[4],
      endOfEatingToPlates: params[5],
      platesToUpsell: params[6],
      upsellToBillReq: params[7],
      billReqToBillDrop: params[8],
      billDropToPayment: params[9],
      paymentToLeave: params[10],
    };
    this.qosOnlyParams = {
      mainToCheckback: params[3]
    };
    this.qosDeviation = params[11];
    this.tablesToHandle = params[12];
    this.qosDrop = params[13];
    this.schedule = {
      Monday: 'off',
      Tuesday: 'off',
      Wednesday: 'off',
      Thursday: 'off',
      Friday: 'off',
      Saturday: 'off',
      Sunday: 'off'
    };
    const schKeys = Object.keys(this.schedule);
    for (let i = 14; i < 21; i++){
      this.schedule[schKeys[i - 14]] = params[i];
    }
    this.customers = [
      // {
      // "startTime": "HH:mm",
      // "endTime": "HH:mm",
      // "guestsNumber": 3,
      // "tableNumber": 12
      // }
    ];

    this.currentWorkload = {
      guests: 0,
      tables: 0
    };

    this.generateDebufs();
  }

  getChance(truthProbability = 0.1){
    return Math.random() <= truthProbability;
  }

  selectNumber(min = 0, max = 7){
    return Math.random() * (max - min) + min;
  }

  selectRandomFromArray(arr, n){
    return arr.sort(() => Math.random() - Math.random()).slice(0, n);
  }

  generateDebufs(){
    if (SimulationService.waiterParams[String(this.id)] === undefined){


    const slowDays = this.getChance(0.5);
    this.debufs.quickUpsellNoAttentionAfter = this.getChance(0.4);
    const slowParams = this.getChance(0.4);
    this.debufs.overwhelmed.forgetToUpsell = this.getChance(0.2);
    this.debufs.overwhelmed.slowOrdering = this.getChance(0.2);
    this.debufs.overwhelmed.slowBill = this.getChance(0.2);
    this.debufs.overwhelmed.fastPlates = this.getChance(0.3);
    this.debufs.overwhelmed.quickPayment = this.getChance(0.3);
    if (slowDays){
      this.debufs.slowDays = this.selectRandomFromArray(Object.keys(this.schedule), this.selectNumber(0, 6));
    }
    if (slowParams){
      this.debufs.slowParams = this.selectRandomFromArray(Object.keys(this.timeAddingParams), this.selectNumber(1, 6));
    }
    console.log('this.debufs', this.debufs);
    SimulationService.waiterParams[String(this.id)] = this.debufs;
    } else {
      this.debufs = SimulationService.waiterParams[String(this.id)];
    }
    // this.debufs = {
    //   slowDays: [],
    //   quickUpsellNoAttentionAfter: false,
    //   slowParams: [],
    //   overwhelmed: {
    //     forgetToUpsell: false,
    //     slowOrdering: false,
    //     slowBill: false,
    //     fastPlates: false,
    //     quickPayment: false,
    //   }
    // };
  }

  getCurrentWorkload(timeSlot){
    const result = {
      guests: 0,
      tables: 0
    };
    this.customers.forEach(customer => {
      if (moment(timeSlot, 'HH:mm').isBefore(moment(customer.endTime, 'HH:mm'))){
        result.guests += customer.guestsNumber;
        result.tables += 1;
      }
    });
    this.currentWorkload = result;
    return result;
  }

  checkAvailability(weekDay, timeslot = undefined){
    const shift = this.schedule[Object.keys(this.schedule)[weekDay - 1]];
    if (shift !== 'off'){
      if(timeslot === undefined) return shift;
      if ((shift === 'morning' && moment(timeslot, 'HH:mm').isBefore(moment(SimulationService.simParams.waiter.shifts.morningEnd, 'HH:mm'))) ||
        (shift === 'evening' && moment(timeslot, 'HH:mm').isAfter(moment(SimulationService.simParams.waiter.shifts.eveningStart, 'HH:mm')))){
        return true;
      } else {
        return false;
      }

    } else {
      return false;
    }
  }
  selectArea(tablesLeft){
    console.log('tablesLeft', tablesLeft)
  }

  simulateServing(guestsNumber, timeslot, weekday){
    // get current load to see if over tablesToHandle
    let inWeeds = 0;
    if (this.tablesToHandle - this.currentWorkload.tables - 1 < 0) {
      inWeeds = -1 * (this.tablesToHandle - this.currentWorkload.tables - 1 );
    }
    // console.log('inWeeds', inWeeds);
    const qos = Object.assign({}, this.timeAddingParams);
    // Object.keys(qos).map(keyfor)
    for (const param of Object.keys(qos)){
      let value = qos[param];



      if (this.debufs.slowParams.indexOf(param) > -1) { value = value + (value * this.selectNumber(0.1, 0.5)); }
      if (this.debufs.slowDays.indexOf(weekday) > -1) { value = value + (value * this.selectNumber(0.2, 0.3)); }

      const deviation = (value / 100) * this.qosDeviation;
      let newNumber = 0;
      if (inWeeds !== 0){
        if (this.debufs.overwhelmed.forgetToUpsell){
          if (param === 'platesToUpsell'){
            value = value + qos.upsellToBillReq;
          }
          if (param === 'upsellToBillReq'){
            value = 0.1;
          }
        }

        if (this.debufs.overwhelmed.slowOrdering && param === 'arrivalToOrder'){
          value = value + (value * this.selectNumber(0.1, 0.3));
        }

        if (this.debufs.overwhelmed.slowBill && param === 'billReqToBillDrop'){
          value = value + (value * this.selectNumber(0.1, 0.3));
        }

        newNumber = this.randn_bm(value - deviation - deviation + (deviation * inWeeds), value + deviation + (deviation * inWeeds), 1);
        if (this.debufs.overwhelmed.fastPlates && param === 'endOfEatingToPlates'){
          newNumber = this.selectNumber(0.3, 1);
        }
        if (this.debufs.overwhelmed.quickPayment && param === 'billDropToPayment'){
          newNumber = this.selectNumber(0.3, 1);
        }
        if (this.debufs.overwhelmed.quickPayment && param === 'billReqToBillDrop'){
          newNumber = this.selectNumber(0.3, 1);
        }
      } else {
        if (this.debufs.quickUpsellNoAttentionAfter){
          if (param === 'platesToUpsell'){
            value = value + qos.upsellToBillReq;
          }
          if (param === 'upsellToBillReq'){
            value = 0.1;
          }
        }

        newNumber = this.randn_bm(value - deviation - deviation, value + deviation , 1);
      }


      // let deviation = (qos[param]/100) * this.qosDeviation;
      // let newNumber = Math.floor(Math.random() * (qos[param] + deviation)) + (qos[param] - deviation);
      if (newNumber < 0) { newNumber = 0; }
      newNumber = Math.round(newNumber * 100) / 100;
      qos[param] = newNumber;
    }

    return qos;
  }

  randn_bm(min, max, skew) {
    let u = 0;
    let v = 0;
    while (u === 0) { u = Math.random(); } // Converting [0,1) to (0,1)
    while (v === 0) { v = Math.random(); }
    let num = Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v );

    num = num / 10.0 + 0.5; // Translate to 0 -> 1
    if (num > 1 || num < 0) { num = this.randn_bm(min, max, skew); } // resample between 0 and 1 if out of range
    num = Math.pow(num, skew); // Skew
    num *= max - min; // Stretch to fill range
    num += min; // offset to min
    return num;
  }
}

class Simulation{

  // TODO assign waiters to areas
 // TODO consider morning and evening shift

  private wb: Workbook;
  private totalGuests: number;
  private tables: any[];
  private waiters: any[];
  private tablesStats: { '1': number; '2': number; totalTables: number; '3': number; '4': number;
  '5': number; totalCapacity: number; '6': number; '7': number; '8': number };

  constructor(wb) {
    this.wb = wb;
    console.log(Object.keys(this.wb.data));
    this.tables = this.loadTables(this.wb.data.Tables);
    this.waiters = this.loadWaiters(this.wb.data['Waiters Specs']);
    this.tablesStats = this.getTablesStats();
  }


  generateDay(weekDay = 1){
    const result = [];
    // this.reloadData();
    this.totalGuests = this.wb.data['Slot simulation'][16][weekDay];
    this.assignTablesToWaiters(weekDay);
    console.log('totalGuests', this.totalGuests);
    for (let slot = 1; slot < 15; slot++) {
      const slotStartHour = this.wb.data['Slot simulation'][slot][0];
      const guestsInSlot = Math.round(this.wb.data['Slot simulation'][slot][weekDay] * this.totalGuests);
      // console.log('guestsInSlot', guestsInSlot);
      const guestGroups = this.splitGuestsInGroups(guestsInSlot, this.wb.data['Group size distribution']);
      const timeSlots = this.generateTimeSlots(guestGroups.length, slotStartHour);
      for (let i = 0; i < guestGroups.length; i++) {
        const avaliableWaiters = this.selectWaiter(timeSlots[i], weekDay);
        if (avaliableWaiters.length === 0){
          // console.log('timeSlots[i], weekDay', timeSlots[i], weekDay)
          console.log('There are no waiters');
          continue;
        }
        // const selectedWaiter = avaliableWaiters[0]
        const {selectedTable, selectedWaiter} = this.selectTable(timeSlots[i], guestGroups[i], avaliableWaiters);
        // console.log('selectedTable, selectedWaiter', selectedTable, selectedWaiter)
        if (selectedTable === null){
          console.log('There are no free tables');
          continue;
        }

        const qos = selectedWaiter.simulateServing(guestGroups[i], timeSlots[i], weekDay);
        let totalTime = 0;
        Object.keys(qos).forEach(param => {
          totalTime += qos[param];
        });
        totalTime = Math.round(totalTime * 100) / 100;
        const endTime = moment(timeSlots[i], 'HH:mm').add(totalTime, 'minutes').format('HH:mm');

        selectedTable.customers.push({
          startTime: timeSlots[i],
          endTime,
          guests: guestGroups[i]
        });
        selectedWaiter.customers.push({
          startTime: timeSlots[i],
          endTime,
          guestsNumber: guestGroups[i],
          tableNumber: selectedTable.number
        });
        // console.log("selectedTable", selectedTable)
        // console.log("selectedWaiter", selectedWaiter)
        // console.log("qos", qos)
        // console.log("startTime", timeSlots[i])
        // console.log("endTime", endTime)
        // console.log("totalTime", totalTime)

        // console.log("selectedTable", selectedTable);
        // console.log("selectedWaiter", selectedWaiter);
        const waiterStats = selectedWaiter.getCurrentWorkload(timeSlots[i]);
        // console.log("waiterStats", waiterStats)
        // console.log("selectedWaiter", selectedWaiter.currentWorkload)
        const restaurantStats = this.getRestaurantOccupancy(timeSlots[i]);
        const output = this.generateOutputLine(selectedWaiter.id, timeSlots[i], endTime,
          guestGroups[i], selectedTable.number, totalTime, weekDay, qos, waiterStats, restaurantStats);
        // console.log("output", output)
        result.push(output);
      }
      // console.log("timeSlots", timeSlots)

      // console.log("guestsInSlot", guestsInSlot)
    }
    return result;
  }

  splitGuestsInGroups(guestsNumber, groupsProbabilities){
    const result = [];
    const probabilityDistribution = [];
    for (let i = 0; i < 8; i++){
      for (let i2 = 0; i2 < groupsProbabilities[i][1] * 100; i2++){
        probabilityDistribution.push(groupsProbabilities[i][0]);
      }
    }
    while (guestsNumber > 0){
      const groupSize = probabilityDistribution[Math.floor(Math.random() * probabilityDistribution.length)];
      result.push(groupSize);
      guestsNumber -= groupSize;
    }
    return result;
  }

  generateTimeSlots(guestsNumber, slotStartHour){
    const result = [];
    for (let i2 = 0; i2 < guestsNumber; i2++){
      // @ts-ignore
      const entranceTime = new moment(moment(String(slotStartHour) + ':' +
        String(Math.floor(Math.random() * 60)), 'HH:mm')).format('HH:mm');
      result.push(entranceTime);
    }
    result.sort();
    return result;
  }

  getRestaurantOccupancy(timeslot){
    const stats = {
      occupiedTables: 0,
      currentGuests: 0
    };
    for (const table of this.tables){
      const lastGroup = table.customers[table.customers.length - 1];
      if (lastGroup === undefined) {

      } else {
        // if () //if last slot ends before the start of the slot, select the table
        if (moment(timeslot, 'HH:mm').isBefore(moment(lastGroup.endTime, 'HH:mm'))){
          stats.occupiedTables += 1;
          stats.currentGuests += lastGroup.guests;
        }
      }
    }

    return stats;
  }


  loadTables(tables){
    console.log('tables', tables);
    const result = [];
    tables.pop();
    for (let i = 1; i < tables.length; i++){
      if (tables[i].length === 0) {
        continue;
      } else {
        if (tables[i][0] === 'Total capacity') { continue; }
      }
      console.log('tables[i]', tables[i].length === 0);
      result.push({
        number: tables[i][0],
        capacity: tables[i][1],
        area: tables[i][2],
        customers: [
          // {
          // "startTime": "HH:mm",
          // "endTime": "HH:mm"
          // }
        ]
      });
    }
    result.sort((a, b) => a.capacity - b.capacity);
    console.log('result', result);
    return result;
  }

  getTablesStats(){
    const tablesStats = {
      totalTables: 0,
      totalCapacity: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0
    };

    for (const table of this.tables){
      tablesStats.totalCapacity += table.capacity;
      tablesStats.totalTables += 1;
      tablesStats[String(table.capacity)] += 1;
    }

    return tablesStats;
  }

  loadWaiters(waiters){
    const result = [];

    waiters.shift();
    waiters.pop();
    for (const waiter of waiters){
      result.push(new Waiter(waiter));
    }
    // console.log("result",result)
    return result;
  }

  selectTable(timeSlot, groupSize, availableWaiters){
    let tableSelection = null;
    let waiterSelection = null;
    const potentialTables = [];
    for (const table of this.tables){
      if (groupSize > table.capacity) { continue; }
      const lastGroup = table.customers[table.customers.length - 1];
      if (lastGroup === undefined) {
        potentialTables.push(table);
        // selection = table;
        // break;
      } else {
        // if () //if last slot ends before the start of the slot, select the table
        const endTime = moment(lastGroup.endTime, 'HH:mm').isBetween(moment('00:00', 'HH:mm'),
          moment('5:00', 'HH:mm'), undefined, '[]') ? moment(lastGroup.endTime, 'HH:mm').add(1, 'day') :
          moment(lastGroup.endTime, 'HH:mm');

        if (endTime.isBefore(moment(timeSlot, 'HH:mm'))){
          // selection = table;
          potentialTables.push(table);
          // break;
        }
      }

    }
    // console.log('potentialTables', potentialTables)
    // console.log('availableWaiters', availableWaiters)
    if (potentialTables.length > 0) {
      availableWaiters.forEach(waiter => {
        if(waiterSelection !== null) return
        potentialTables.forEach(table => {
          if(waiterSelection !== null) return
          // console.log('Object.keys(waiter.area).indexOf(table.number)', )
          if(waiter.area.find(tableData => tableData.number === table.number) !== undefined){
            waiterSelection = waiter;
            tableSelection = table;
          }
        });

      });
      // tableSelection = potentialTables[Math.floor(Math.random() * potentialTables.length)];
    }
    // selectedTable, selectedWaiter
    return {selectedTable: tableSelection, selectedWaiter: waiterSelection};
  }

  selectWaiter(timeSlot, weekDay){
    // firstly we construct a list of waiters that are avliable on the day and their current workload

    // console.log(this.waiters)
    const waitersList = [];
    for (const waiter of this.waiters){

      const availability = waiter.checkAvailability(weekDay, timeSlot);
      if (availability === false) { continue; }
      waiter.getCurrentWorkload(timeSlot);
      waitersList.push(waiter);
    }
    waitersList.sort((a, b) => (b.tablesToHandle - b.currentWorkload.tables - 1) - (a.tablesToHandle - a.currentWorkload.tables - 1));
    return waitersList;
  }


  generateOutputLine(waiterId, startTime, endTime, guestsNumber, tableNumber, length, weekday, qos, waiterStats, restaurantStats){
//    lunch from 11:30 to 13:00
//    dinner 18:30 to 22:00
    let timeslot;
    const middleTime = moment(endTime, 'HH:mm').subtract(30, 'minutes');
    if (middleTime.isBetween(moment('11:30', 'HH:mm'), moment('13:00', 'HH:mm'))) {
      timeslot = 'Lunch';
    } else {
      if (middleTime.isBetween(moment('18:30', 'HH:mm'), moment('22:00', 'HH:mm'))) {
        timeslot = 'Dinner';
      } else {
        timeslot = 'Dead';
      }
    }


    const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    // console.log('waiterStats', waiterStats);
    // console.log('restaurantStats', restaurantStats);
    const data = {
      waiterId,
      arrivalToOrder: qos.arrivalToOrder,
      orderToMain: qos.orderToMain,
      mainToEndOfEating: qos.mainToEndOfEating,
      endOfEatingToPlates: qos.endOfEatingToPlates,
      platesToUpsell: qos.platesToUpsell,
      upsellToBillReq: qos.upsellToBillReq,
      billReqToBillDrop: qos.billReqToBillDrop,
      billDropToPayment: qos.billDropToPayment,
      paymentToLeave: qos.paymentToLeave,
      tableNumber,
      startTime,
      endTime,
      length,
      weekday: weekdays[weekday - 1],
      daySlot: timeslot,
      groupSize: guestsNumber,
      concurentTables: waiterStats.tables,
      concurentGuests: waiterStats.guests,
      restaurantOccupancy: restaurantStats.currentGuests,
      restuarntOccpiedTables: restaurantStats.occupiedTables,
      totalTables: this.tablesStats.totalTables,
      totalCapacity: this.tablesStats.totalCapacity,
      Tables_sized_1: this.tablesStats['1'],
      Tables_sized_2: this.tablesStats['2'],
      Tables_sized_3: this.tablesStats['3'],
      Tables_sized_4: this.tablesStats['4'],
      Tables_sized_5: this.tablesStats['5'],
      Tables_sized_6: this.tablesStats['6'],
      Tables_sized_7: this.tablesStats['7'],
      Tables_sized_8: this.tablesStats['8']
    };
    return data;
  }
sortArrayRandom(array){
  for(let i = 0; i < array.length; i++){
    const j = Math.floor(Math.random() * i)
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
}
  private assignTablesToWaiters(weekday) {
    const waitersList = {};

    const tablesLeft = Object.assign([], this.tables);
    this.sortArrayRandom(this.waiters);
    for (const waiter of this.waiters){

      const availability = waiter.checkAvailability(weekday);
      if (availability === false) { continue; }
      if(waitersList[availability] === undefined) waitersList[availability] = []
      waitersList[availability].push(waiter);
    }
    Object.keys(waitersList).forEach(shift => {
      const tempTables = Object.assign([], tablesLeft);
      const tablePerWaiter = Math.ceil(tempTables.length / waitersList[shift].length);
      waitersList[shift].forEach(waiter => {
        waiter.area = tempTables.splice(0, tablePerWaiter);
      })
    })

    // waiter.selectArea(tablesLeft)
  }
}
