import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {ChartsModule} from 'ng2-charts';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as ColorSchemes from 'chartjs-plugin-colorschemes';
import {Ng5SliderModule} from 'ng5-slider';
import {Ng2GoogleChartsModule} from 'ng2-google-charts';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {EmployeeDialogContent, OverviewComponent} from "./overview/overview.component";
// import {MaterialDataModule} from '../material-data/material-data.module';
import {DemoMaterialModule} from "../demo-material-module";

import {TotalsStackComponent} from './main-charts/totals-stack/totals-stack.component';
import {AreaStackComponent} from './main-charts/area-stack/area-stack.component';
import {TargetQosChartComponent} from './main-charts/target-qos-chart/target-qos-chart.component';
import {QosDeviationChartComponent} from './main-charts/qos-deviation-chart/qos-deviation-chart.component';
import {TimelineChartComponent} from './main-charts/timeline-chart/timeline-chart.component';
import {PieChartComponent} from './main-charts/pie-chart/pie-chart.component';
// import {StarterRoutes} from "../starter/starter.routing";
import {EyezillaChartsRoutes} from "./eyezilla_charts.routing";
import {BarBasicComponent} from './main-charts/bar-basic/bar-basic.component';

Chart.plugins.unregister(ChartDataLabels);
Chart.plugins.register(ColorSchemes);

@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    Ng5SliderModule,
    Ng2GoogleChartsModule,
    // MaterialDataModule,
    FlexLayoutModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(EyezillaChartsRoutes)
  ],
  declarations: [
    TotalsStackComponent,
    OverviewComponent,
    AreaStackComponent,
    TargetQosChartComponent,
    QosDeviationChartComponent,
    TimelineChartComponent,
    PieChartComponent,
    BarBasicComponent,
    EmployeeDialogContent
  ],

})
export class EyezillaChartsModule { }
