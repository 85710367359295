import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  apiUrl = environment.apiUrl;
  loaded = false;
  privacyText;

  constructor(private httpClient: HttpClient) { }

  async ngOnInit() {
    const res: any = await this.httpClient
        .get(this.apiUrl + '/privacy')
        .toPromise();
    this.privacyText = res.text;
    this.loaded = true;
    // console.log('res', res)
  }

}
