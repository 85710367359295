import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  // {
  //   state: 'starter',
  //   name: 'Starter Page',
  //   type: 'link',
  //   icon: 'content_copy'
  // },
  // {
  //   state: 'influencer',
  //   name: 'Influencers',
  //   type: 'sub',
  //   icon: 'analytics',
  //   children: [
  //     { state: 'list', name: 'List', type: 'link' },
  //       // { state: 'view', name: 'View', type: 'link' },
  //       ]
  // },
  // {
  //   state: 'Venue comparison',
  //   name: 'Venue comparison',
  //   type: 'link',
  //   icon: 'compare'
  // },
  // {
  //   state: 'influencer',
  //   name: 'Influencers',
  //   type: 'link',
  //   icon: 'analytics'
  // },
  {
    state: 'dashboard',
    name: 'Dashboard',
    type: 'link',
    icon: 'dashboard'
  },
  {
    state: 'demo',
    name: 'Demo',
    type: 'link',
    icon: 'analytics'
  },
  // {
  //   state: 'subscription',
  //   name: 'Подписка',
  //   type: 'link',
  //   icon: 'card_membership',
  //   // children: [
  //   //       { state: 'list', name: 'My Subscription', type: 'link' },
  //   //       { state: 'buy', name: 'Buy Subscription', type: 'link' },
  //   // ]
  // },
  // {
  //   state: 'profile',
  //   name: 'Мой профиль',
  //   type: 'link',
  //   icon: 'account_box'
  // },
  {
    state: 'saperator',
    name: 'test',
    type: 'saperator',
    icon: 'account_box'
  },
  // {
  //   state: 'https://go.crisp.chat/chat/embed/?website_id=b0cc031d-f219-42f1-bb3b-8ef4094c0d01',
  //   name: 'Чат с поддержкой',
  //   type: 'extTabLink',
  //   icon: 'chat'
  // },
  {
    state: 'https://go.crisp.chat/chat/embed/?website_id=b0cc031d-f219-42f1-bb3b-8ef4094c0d01',
    name: '© Eyezilla, 2020',
    type: 'info',
    icon: 'chat'
  },
  // {
  //   state: 'material',
  //   name: 'Material Ui',
  //   type: 'sub',
  //   icon: 'bubble_chart',
  //   badge: [{ type: 'red', value: '17' }],
  //   children: [
  //     { state: 'badge', name: 'Badge', type: 'link' },
  //     { state: 'button', name: 'Buttons', type: 'link' },
  //     { state: 'cards', name: 'Cards', type: 'link' },
  //     { state: 'grid', name: 'Grid List', type: 'link' },
  //     { state: 'lists', name: 'Lists', type: 'link' },
  //     { state: 'menu', name: 'Menu', type: 'link' },
  //     { state: 'tabs', name: 'Tabs', type: 'link' },
  //     { state: 'stepper', name: 'Stepper', type: 'link' },
  //     { state: 'ripples', name: 'Ripples', type: 'link' },
  //     { state: 'expansion', name: 'Expansion Panel', type: 'link' },
  //     { state: 'chips', name: 'Chips', type: 'link' },
  //     { state: 'toolbar', name: 'Toolbar', type: 'link' },
  //     { state: 'progress-snipper', name: 'Progress snipper', type: 'link' },
  //     { state: 'progress', name: 'Progress Bar', type: 'link' },
  //     { state: 'dialog', name: 'Dialog', type: 'link' },
  //     { state: 'tooltip', name: 'Tooltip', type: 'link' },
  //     { state: 'snackbar', name: 'Snackbar', type: 'link' },
  //     { state: 'slider', name: 'Slider', type: 'link' },
  //     { state: 'slide-toggle', name: 'Slide Toggle', type: 'link' }
  //   ]
  // }
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
