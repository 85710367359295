import { Component, OnInit, Input } from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import * as ColorSchemes from 'chartjs-plugin-colorschemes';
import {ColorSchemesService} from '../../services/color-schemes.service';

@Component({
  selector: 'app-area-stack',
  templateUrl: './area-stack.component.html',
  styleUrls: ['./area-stack.component.css']
})
export class AreaStackComponent implements OnInit {
  @Input()
  // tslint:disable-next-line:variable-name
  public set inputData(_data: any){
    this.data = _data;
    this.updateData();
  }
  data = undefined;

  constructor(public colorSchemes: ColorSchemesService) { }

  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    // legend:{
    //   labels: {
    //     fontColor: 'white'
    //   }
    // },
    scales: {
      yAxes: [{
        id: 'A',
        type: 'linear',
        position: 'left',
        ticks: {
          beginAtZero: true,
          // fontColor: 'white'
        }
      }],
      xAxes: [{
        ticks: {
          // fontColor: 'white'
        }
      }]
    },
    plugins:{
      colorschemes: {
        scheme: this.colorSchemes.colors.weekdays,
        override: true
        // reverse: true,
      }
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
  };
  chartLabels: Label[] = [];
  chartType: ChartType = 'line';
  chartLegend = true;
  chartPlugins = [ColorSchemes];

  chartData: ChartDataSets[] = [];
  loadedComponent: boolean;

  ngOnInit(): void {
  }

  updateData(){
    if (this.data === undefined) { return; }
    this.loadedComponent = false;
    // console.log('this.data', this.data);
    this.chartLabels = this.data.labels;
    this.chartData = [];
    for (const key of Object.keys(this.data.extendedData)){
      this.chartData.push({data: this.data.extendedData[key], label: key});
    }
    setTimeout(x => this.loadedComponent = true);
  }

}
