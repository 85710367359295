import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgStrapiAuthService} from "../../../services/strapi/auth/ng-strapi-auth.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  public config: PerfectScrollbarConfigInterface = {};

  constructor(private authSerice: NgStrapiAuthService){

  }
  async signOut(){
      await this.authSerice.signOut();
  }
}
